/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import Contact from 'requests/objects/contact'
import Client from 'requests/objects/client'
import AddressTypeDefault from 'requests/objects/addressTypeDefault'
import OperatingCenter from 'requests/objects/operatingCenter'
import Country from 'requests/objects/country'
import Company from 'requests/objects/company'
import Accessibility from 'requests/objects/accessibility'

/**
 * Address Object
 */
export default class Address extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.addressId addressId
     * @param {string=} data.addressTmsId addressTmsId
     * @param {string=} data.street street
     * @param {string=} data.city city
     * @param {string=} data.zipCode zipCode
     * @param {number=} data.clientId clientId
     * @param {object=} data.client client
     * @param {number=} data.companyId companyId
     * @param {object=} data.company company
     * @param {number=} data.accessibilityId accessibilityId
     * @param {object=} data.accessibility accessibility
     * @param {number=} data.vehicleEquipmentId vehicleEquipmentId
     * @param {boolean=} data.isValidated isValidated
     * @param {boolean=} data.isVisible isVisible
     * @param {boolean=} data.isPlatform isPlatform
     * @param {string=} data.lon lon
     * @param {string=} data.lat lat
     * @param {string=} data.label label
     * @param {string=} data.complement complement
     * @param {string=} data.instructions instructions
     * @param {boolean=} data.isSurtaxed isSurtaxed
     * @param {string=} data.comment comment
     * @param {number=} data.countryId countryId
     * @param {object=} data.country country
     * @param {string=} data.startTime startTime
     * @param {string=} data.endTime endTime
     * @param {string=} data.phoneNumber phoneNumber
     * @param {number=} data.contactId contactId
     * @param {object=} data.contact contact
     * @param {object[]=} data.addressTypeDefaults addressTypeDefaults
     * @param {number[]=} [data.addressTypeIds] addressTypeIds
     * @param {boolean=} data.isManual isManual
     * @param {number=} data.operatingCenterId operatingCenterId
     * @param {object=} data.operatingCenter operatingCenter
     */
    constructor({
        addressId = null,
        addressTmsId = '',
        street = '',
        city = '',
        zipCode = '',
        clientId,
        client,
        companyId,
        company,
        accessibilityId = 1,
        accessibility,
        vehicleEquipmentId = 1,
        isValidated = false,
        isVisible = true,
        isPlatform = false,
        lon = '',
        lat = '',
        label = '',
        complement = '',
        instructions = '',
        isSurtaxed = false,
        comment = '',
        countryId,
        country,
        startTime = '',
        endTime = '',
        phoneNumber = '',
        contactId,
        contact,
        addressTypeDefaults,
        addressTypeIds = [],
        isManual = false,
        operatingCenterId,
        operatingCenter,
        ...data
    } = {}) {
        super(data)
        this.addressId = addressId
        this.addressTmsId = addressTmsId
        this.street = street
        this.city = city
        this.zipCode = zipCode
        this.clientId = clientId
        this.client = client ? new Client(client) : undefined
        this.companyId = companyId
        this.company = company ? new Company(company) : undefined
        this.accessibilityId = accessibilityId
        this.accessibility = accessibility ? new Accessibility(accessibility) : undefined
        this.vehicleEquipmentId = vehicleEquipmentId
        this.isValidated = isValidated
        this.isVisible = isVisible
        this.isPlatform = isPlatform
        this.lon = lon
        this.lat = lat
        this.label = label
        this.complement = complement
        this.instructions = instructions
        this.isSurtaxed = isSurtaxed
        this.comment = comment
        this.countryId = countryId
        this.country = country ? new Country(country) : undefined
        this.startTime = startTime
        this.endTime = endTime
        this.phoneNumber = phoneNumber
        this.contactId = contactId
        this.contact = contact ? new Contact(contact) : undefined
        this.addressTypeDefaults = addressTypeDefaults?.map(x => new AddressTypeDefault(x)) ?? []
        // eslint-disable-next-line no-nested-ternary
        this.addressTypeIds = this.addressTypeDefaults?.length
            ? this.addressTypeDefaults.map(x => x.addressTypeId) : addressTypeIds?.length ? addressTypeIds : []
        this.isManual = isManual
        this.operatingCenterId = operatingCenterId
        this.operatingCenter = operatingCenter ? new OperatingCenter(operatingCenter) : undefined
    }
}

/**
 * Address Object used to bind error message
 */
export class ErrorAddress extends ErrorBase {
    constructor() {
        super()
        this.addressId = ''
        this.addressTmsId = ''
        this.street = ''
        this.city = ''
        this.zipCode = ''
        this.clientId = ''
        this.client = ''
        this.companyId = ''
        this.company = ''
        this.accessibilityId = ''
        this.vehicleEquipmentId = ''
        this.accessibility = ''
        this.isValidated = ''
        this.isVisible = ''
        this.lon = ''
        this.lat = ''
        this.label = ''
        this.complement = ''
        this.instructions = ''
        this.isSurtaxed = ''
        this.comment = ''
        this.startTime = ''
        this.endTime = ''
        this.zipCode = ''
        this.phoneNumber = ''
        this.contact = ''
        this.addressTypeIds = ''
        this.addressType = ''
        this.countryId = ''
        this.isManual = ''
        this.operatingCenterId = ''
        this.operatingCenter = ''
    }
}
