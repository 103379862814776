import React, { PureComponent } from 'react'
import {
    DefaultButton,
} from '@fluentui/react'
import history from 'helpers/history'
import Order, { ErrorOrder } from 'requests/objects/order'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import { isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import styles from 'styles/pages/orders/[id]/client/id-client.module.scss'
import WorkflowLight from 'components/pages/workflowLight'
import ConfirmationStep from 'components/pages/orders/[id]/client/confirmationStep'
import DeliveryStep from 'components/pages/orders/[id]/client/deliveryStep'
import PickupStep from 'components/pages/orders/[id]/client/pickupStep'
import parseJson from 'helpers/methods/parseJson'
import EProfile from 'types/users/enums/profile'
import { ErrorDeliveryData } from 'requests/objects/order/deliveryData'
import EOrderStatus from 'types/orders/enums/orderStatus'
import EWorkflowStep from 'types/orders/enums/workflowStep'

/** @debug {import('app').AppProps} */

/**
 * @augments {PureComponent<import('app').AppProps>}
 */
export default class IdAdminOrders extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: true,
            /** @type {Order} Element find from API */
            item: new Order(),
            /** @type {ErrorOrder} Errors */
            errorField: new ErrorOrder(),
            /** @type {ErrorDeliveryData} Errors */
            deliveryErrorField: new ErrorDeliveryData(),
            /** Current page index */
            currentPageIndex: 0,
            /** Delviery index to display */
            deliveryIndex: 0,
        }

        this.submitInput = React.createRef()
        // this.validateAddress = this.validateAddress.bind(this)
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const {
            match, lang,
        } = this.props

        const { currentPageIndex } = this.state

        if (match?.params?.orderId !== prevProps?.match?.params?.orderId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                this.setupBreadcrumb()
                this.setupCommandBar()
            }

        if (lang !== prevProps.lang) {
            this.setupBreadcrumb()
            this.setupCommandBar()
        }

        if (currentPageIndex !== prevState.currentPageIndex)
            this.setupCommandBar()
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.ordersHandlerGetById?.cancel()
        this.ordersHandlerUpsert?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props
        const { item } = this.state

        const breadCrumb = [
            { text: this.tKey('myOrders'), key: 'all-order', href: '/orders' },
            {
                text: `${this.tKey('order')}${item.orderId ? ` n°${item.orderId}` : ''}`,
                key: 'one-order',
            },
            {
                text: item.currentWorkflowStepId ? this.tObj(this.parseJson(item.currentWorkflowStep.text)) : this.tKey('creation'),
                key: 'workflow-step',
                isCurrentItem: true,
            },
        ]

        setBreadcrumb(breadCrumb)
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand } = this.props
        const { item, currentPageIndex } = this.state

        /**
         * @type {import('@fluentui/react').ICommandBarItemProps[]} Commanbar items
         */
        this.commands = [
            {
                key: 'back',
                text: this.tKey('return'),
                iconProps: { iconName: 'Back' },
                onClick: () => {
                    if (item.currentWorkflowStepId !== EWorkflowStep.Creation && currentPageIndex !== 2)
                        this.setState({ currentPageIndex: 2 })
                    else
                        history.push('/orders')
                },
            },
        ]

        setCommand(this.commands)
    }

    /**
     * get isReadOnly
     * @param {Partial<Order>} order order
     * @returns {boolean} isReadOnly
     */
    getIsReadOnly({ isCreation, isOperatorsValidation }) {
        const { me: { profileId } } = this.props
        const isAdmin = [EProfile.Admin, EProfile.SuperAdmin].includes(profileId)
        const isClient = [EProfile.CustomOrderEntry, EProfile.CustomerAdmin].includes(profileId)

        if (isCreation)
            return !isClient
        if (isOperatorsValidation)
            return !isAdmin && profileId !== EProfile.Operator
        return true
    }

    /**
     * Get current page index from order
     * @param {Order} order order
     * @returns {[number, number]} [currentPageIndex, deliveryIndex]
     */
    // eslint-disable-next-line class-methods-use-this
    getCurrentPageIndexFromOrder(order) {
        if (order.isAddressValidation)
            if (!order.pickupAddressData.address.isValidated)
                return [0, 0]
            else
                // eslint-disable-next-line no-restricted-syntax
                for (const [index, deliveryData] of order.deliveryDatas.entries())
                    if (!deliveryData.deliveryAddressData.address.isValidated)
                        return [1, index]
        return [2, 0]
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, ordersHandler, setMessageBar,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })

        this.setState({ status: Status.PENDING }, async () => {
            try {
                this.ordersHandlerGetById = ordersHandler.getById(match?.params?.orderId)
                const order = await this.ordersHandlerGetById.fetch()
                const [currentPageIndex, deliveryIndex] = this.getCurrentPageIndexFromOrder(order)

                const isReadOnly = this.getIsReadOnly(order)

                this.setState({
                    item: order,
                    currentPageIndex,
                    deliveryIndex,
                    status: Status.RESOLVED,
                    isReadOnly,
                }, () => {
                    this.setupBreadcrumb()
                    this.setupCommandBar()
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        setCommand(this.commands)
                        this.setState({ status: Status.REJECTED })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, ordersHandler, match, setMessageBar,
        } = this.props
        const { item, currentPageIndex, deliveryIndex } = this.state

        this.setState({ status: Status.PENDING }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.ordersHandlerUpsert = currentPageIndex === 0
                    ? ordersHandler.upsert(item, match?.params?.orderId)
                    : ordersHandler.updateDeliveryData(item.deliveryDatas[deliveryIndex], match?.params?.orderId)

                const order = await this.ordersHandlerUpsert.fetch()

                const [currentPageIndexResolved, deliveryIndexResolved] = this.getCurrentPageIndexFromOrder(order)

                this.setState({
                    item: order,
                    status: Status.RESOLVED,
                    currentPageIndex: currentPageIndexResolved,
                    deliveryIndex: deliveryIndexResolved,
                    isReadOnly: this.getIsReadOnly(order),
                })
                setMessageBar({ isDisplayed: false })

                this.setupCommandBar()
                setCommand(this.commands)
            } catch (error) {
                this.setState({ status: Status.REJECTED }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorOrder>} */(error).errorField })
                            setCommand(this.commands)
                            break
                        default:
                            setCommand(this.commands)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    // /**
    //  * Validate address
    //  * @param {number} addressId addressId
    //  */
    // validateAddress(addressId) {
    //     const { ordersHandler, setMessageBar } = this.props
    //     const { item } = this.state

    //     setMessageBar({ isDisplayed: false })
    //     this.setState({ status: Status.PENDING }, async () => {
    //         try {
    //             this.validateAddressHandler = ordersHandler.validAddress(item.orderId, addressId)
    //             const order = await this.validateAddressHandler.fetch()

    //             setMessageBar({ isDisplayed: false })

    //             this.setState({
    //                 item: order,
    //                 status: Status.RESOLVED,
    //             })
    //         } catch (error) {
    //             this.setState({ status: Status.REJECTED }, () => {
    //                 switch (error?.constructor) {
    //                     case CancelRequestError:
    //                     case UnauthorizedError:
    //                     case NotImplementedError:
    //                     case InvalidEntityError:
    //                     default:
    //                         // eslint-disable-next-line no-console
    //                         console.error(error)
    //                         break
    //                 }
    //             })
    //         }
    //     })
    // }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    validateOrder() {
        const {
            ordersHandler, setMessageBar,
        } = this.props
        const { item } = this.state

        this.setState({ status: Status.PENDING }, async () => {
            setMessageBar({ isDisplayed: false })
            try {
                this.validOrderHandler = ordersHandler.validOrder(item.orderId)
                const order = await this.validOrderHandler.fetch()

                this.setState({
                    item: order,
                    status: Status.RESOLVED,
                    isReadOnly: this.getIsReadOnly(order),
                }, () => {
                    this.setupBreadcrumb()
                    this.setupCommandBar()
                })
            } catch (error) {
                this.setState({ status: Status.REJECTED }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError:
                        case NotImplementedError:
                        case InvalidEntityError:
                        default:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField, currentPageIndex, deliveryIndex, deliveryErrorField,
        } = this.state
        const {
            param, lang, addressesHandler, ordersHandler, editParam, addParam, removeParam,
            filesHandler, setModal, setMessageBar, me,
        } = this.props

        if (status === Status.PENDING)
            return <Loader />

        return (
            <main className={styles['id-client']}>
                <div className={styles['id-client-top']}>
                    <div />
                    <WorkflowLight
                        steps={[
                            { label: this.tKey('pickup'), isCompleted: currentPageIndex >= 0 },
                            { label: this.tKey('deliverys'), isCompleted: currentPageIndex >= 1 },
                            {
                                label: this.tKey('confirmation'),
                                isCompleted: currentPageIndex >= 2 && item.orderStatusId === EOrderStatus.Validated,
                                isActive: currentPageIndex >= 2 && item.orderStatusId !== EOrderStatus.Validated,
                                isWarning: currentPageIndex >= 2 && item.orderStatusId === EOrderStatus.Rejected,
                            },
                        ]}
                    />
                    <div className={styles['id-client-btns']}>
                        {currentPageIndex === 1 && (
                            <DefaultButton
                                text={this.tKey('previous')}
                                className={styles['id-client-btns-green-btn']}
                                iconProps={{ iconName: 'Back' }}
                                onClick={() => this.setState({ currentPageIndex: 0 })}
                            />
                        )}
                        {currentPageIndex < 2 && (
                            <DefaultButton
                                text={this.tKey('next')}
                                className={styles['id-client-btns-green-btn']}
                                iconProps={{ iconName: 'Forward' }}
                                styles={{ flexContainer: { flexDirection: 'row-reverse' } }}
                                onClick={() => this.submitInput.current.click()}
                            />
                        )}
                        {currentPageIndex === 2
                            && !item.isCreation
                            && !item.isOrderPlacedInImagine
                            && ((item.isAddressValidation && me.profileId !== EProfile.Operator)
                                || (item.isOperatorsValidation && me.profileId !== EProfile.Commercial))
                            && (
                                <DefaultButton
                                    text={this.tKey('validate')}
                                    className={styles['id-client-btns-green-btn']}
                                    iconProps={{ iconName: 'Forward' }}
                                    styles={{ flexContainer: { flexDirection: 'row-reverse' } }}
                                    onClick={() => this.submitInput.current.click()}
                                />
                            )}
                    </div>
                </div>

                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        if (currentPageIndex === 2)
                            this.validateOrder()
                        if ((item.isAddressValidation && me.profileId !== EProfile.Operator)
                            || (item.isOperatorsValidation && me.profileId !== EProfile.Commercial))
                            this.upsert()
                        else
                            this.setState({ currentPageIndex: currentPageIndex + 1 })
                    }}
                >
                    {currentPageIndex === 0 && (
                        <PickupStep
                            lang={lang}
                            item={item}
                            errorField={errorField}
                            onChange={newData => this.setState(prevState => ({ item: { ...prevState.item, ...newData } }))}
                            param={param}
                            addressesHandler={addressesHandler}
                            editParam={editParam}
                            addParam={addParam}
                            removeParam={removeParam}
                            me={me}
                            isReadOnly={isReadOnly}
                        />
                    )}

                    {currentPageIndex === 1 && (
                        <DeliveryStep
                            lang={lang}
                            item={item}
                            deliveryIndex={deliveryIndex}
                            isReadOnly={isReadOnly}
                            errorField={deliveryErrorField}
                            onChange={newData => this.setState(prevState => ({ item: { ...prevState.item, ...newData } }))}
                            param={param}
                            addressesHandler={addressesHandler}
                            editParam={editParam}
                            addParam={addParam}
                            removeParam={removeParam}
                            filesHandler={filesHandler}
                            me={me}
                        />
                    )}

                    {currentPageIndex === 2 && (
                        <ConfirmationStep
                            userCanEdit={!isReadOnly}
                            lang={lang}
                            item={item}
                            setDeliveryIndex={newIndex => this.setState({ deliveryIndex: newIndex })}
                            setCurrentPageIndex={newIndex => this.setState({ currentPageIndex: newIndex })}
                            onChange={newData => this.setState(prevState => ({ item: { ...prevState.item, ...newData } }))}
                            param={param}
                            ordersHandler={ordersHandler}
                            setModal={setModal}
                            setMessageBar={setMessageBar}
                            company={me.company}
                        />
                    )}

                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={this.submitInput}
                        tabIndex={-1}
                    />
                </form>
            </main>
        )
    }
}

IdAdminOrders.prototype.isValidDate = isValidDate
IdAdminOrders.prototype.tKey = tKey
IdAdminOrders.prototype.tObj = tObj
IdAdminOrders.prototype.parseJson = parseJson
