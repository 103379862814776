/* eslint-disable max-len */
/**
 * @typedef {keyof Translations} TranslationsType
 */
/**
 * @typedef {{"en": string, "fr" :string, "nl": string}} TranslationsObjectType
 */
/**
 * @typedef {keyof Translations["default"]} LangsType
 */

export const Langs = ['en', 'fr', 'nl']

export const Translations = {
    appName: {
        en: 'Client Portal',
        fr: 'Portail client',
        nl: 'Klantenportaal',
    },
    printLoadingSheet: {
        en: 'Print loading sheet',
        fr: 'Imprimer feuille de chargement',
        nl: 'Laadblad afdrukken',
    },
    seller: {
        en: 'Seller',
        fr: 'Vendeur',
        nl: 'Verkoper',
    },
    sellerTooltipMessage: {
        en: 'Here you can enter the name you want to appear to your client when proposing an appointment.',
        fr: 'Vous pouvez renseigner ici le nom que vous souhaitez faire apparaître à votre client lors de la proposition de rendez-vous',
        nl: 'U kunt hier de naam invoeren die u aan uw klant wilt laten verschijnen bij het voorstellen van een afspraak',
    },
    export: {
        en: 'Export',
        fr: 'Exporter',
        nl: 'Exporteren',
    },
    accessibility: {
        en: 'Accessibility',
        fr: 'Accessibilité',
        nl: 'Toegankelijkheid',
    },
    accessibilities: {
        en: 'Accessibilities',
        fr: 'Accessibilités',
        nl: 'Toegankelijkheid',
    },
    chooseDeliveryType: {
        en: 'Choose delivery type',
        fr: 'Choisissez le type de livraison',
        nl: 'Kies het type levering',
    },
    ladDeliveryTypeDateMessage: {
        en: 'The appointment date will be made directly by the recipient via our appointment booking module.',
        fr: 'La date de rendez-vous sera prise directement par le destinataire via notre module de prise de RDV.',
        nl: 'De afspraakdatum wordt rechtstreeks door de ontvanger vastgelegd via onze afspraakboekingsmodule.',
    },
    stocks: {
        en: 'Stocks',
        fr: 'Stocks',
        nl: 'Aandelen',
    },
    useStocks: {
        en: 'Can the customer see their inventory?',
        fr: 'Le client peut-il voir ses stocks ?',
        nl: 'Kan de klant zijn voorraad zien?',
    },
    containerCode: {
        en: 'Container code',
        fr: 'Code conteneur',
        nl: 'Containercode',
    },
    recovery: {
        en: 'Recovery',
        fr: 'Reprise',
        nl: 'Herstel',
    },
    redelivery: {
        en: 'Redelivery',
        fr: 'Re-livraison',
        nl: 'Herbezorging',
    },
    orderType: {
        en: 'Order type',
        fr: 'Type de commande',
        nl: 'Besteltype',
    },
    vehicleEquipment: {
        en: 'Vehicle equipment',
        fr: 'Equipement du véhicule',
        nl: 'Voertuiguitrusting',
    },
    default: { // Do not delete
        en: 'default',
        fr: 'défaut',
        nl: 'standaard',
    },
    isDefault: {
        en: 'is default ?',
        fr: 'est par défaut ?',
        nl: 'is standaard ?',
    },
    description: {
        en: 'description',
        fr: 'description',
        nl: 'beschrijving',
    },
    login: {
        en: 'Login',
        fr: 'Connexion',
        nl: 'Verbinding',
    },
    loadingStartWindow: {
        en: 'Loading start window',
        fr: 'Horaire début de chargement',
        nl: 'Laadstartvenster',
    },
    loadingEndWindow: {
        en: 'Loading end window',
        fr: 'Horaire fin de chargement',
        nl: 'Laadeindvenster',
    },
    departmentNotAllowed: {
        en: 'Your address is not supported by our shipping plan. Please contact {{companyName}} sales department to place your order: {{email}}',
        fr: "Votre adresse n'est pas pris en charge par notre plan de transport. Merci de contacter le service commercial {{companyName}} pour réaliser votre commande : {{email}}",
        nl: 'Uw adres wordt niet ondersteund door ons transportplan. Neem contact op met de verkoopafdeling van {{companyName}} om uw bestelling te plaatsen: {{email}}',
    },
    deliveryStartWindow: {
        en: 'Delivery start window',
        fr: 'Créneau début de livraison',
        nl: 'Leveringsstartvenster',
    },
    deliveryEndWindow: {
        en: 'Delivery end window',
        fr: 'Créneau fin de livraison',
        nl: 'Leveringseindvenster',
    },
    deliveryTime: {
        en: 'Delivery time',
        fr: 'délai de livraison',
        nl: 'leveringstijd',
    },
    deliveryTimes: {
        en: 'Delivery times',
        fr: 'délais de livraison',
        nl: 'Levertijden',
    },
    deliveryTimeUnit: {
        en: 'D+{{deliveryTime}}',
        fr: 'J+{{deliveryTime}}',
        nl: 'D+{{deliveryTime}}',
    },
    deliveryMorning: {
        en: 'Delivery planned on {{date}} between 08:00 and 13:00',
        fr: 'Livraison Planifiée le {{date}} entre 08:00 et 13:00',
        nl: 'Levering gepland op {{date}} tussen 08:00 en 13:00',
    },
    deliveryAfternoon: {
        en: 'Delivery planned on {{date}} between 13:00 and 18:00',
        fr: 'Livraison Planifiée le {{date}} entre 13:00 et 18:00',
        nl: 'Levering gepland op {{date}} tussen 13:00 en 18:00',
    },
    planned: {
        en: 'Planned',
        fr: 'Planifié',
        nl: 'Gepland',
    },
    home: {
        en: 'Home',
        fr: 'Accueil',
        nl: 'Ontvangst',
    },
    title: {
        en: 'Title',
        fr: 'Titre',
        nl: 'Titel',
    },
    actualityDescription: {
        en: 'Actuality description',
        fr: "Description de l'actualité",
        nl: 'Actualiteit beschrijving',
    },
    cancel: {
        en: 'Cancel',
        fr: 'Annuler',
        nl: 'Annuleren',
    },
    save: {
        en: 'Save',
        fr: 'Enregistrer',
        nl: 'Opnemen',
    },
    saveOrder: {
        en: 'Save order',
        fr: 'Enregistrer la commande',
        nl: 'Bewaar de bestelling',
    },
    filters: {
        en: 'Filters',
        fr: 'Filtres',
        nl: 'Filters',
    },
    reduce: {
        en: 'Reduce',
        fr: 'Réduire',
        nl: 'Verminderen',
    },
    extend: {
        en: 'Extend',
        fr: 'Agrandir',
        nl: 'Verlengen',
    },
    validate: {
        en: 'Validate',
        fr: 'Valider',
        nl: 'Bevestigen',
    },
    validateAddress: {
        en: 'Validate address',
        fr: "Valider l'addresse",
        nl: 'Bevestig adres',
    },
    validatedAddress: {
        en: 'Validated address',
        fr: 'Adresse validé',
        nl: 'Afleveradres',
    },
    add: {
        en: 'Add',
        fr: 'Ajouter',
        nl: 'Toevoegen',
    },
    integrateAddresses: {
        en: 'Integrate addresses',
        fr: 'Intégrer des adresses',
        nl: 'Adressen integreren',
    },
    integrateDepartments: {
        en: 'Integrate departments',
        fr: 'Intégrer des départements',
        nl: 'Integreer afdelingen',
    },
    integrateDeliveryTimes: {
        en: 'Integrate delivery times',
        fr: 'Intégrer des délais de livraison',
        nl: 'Integreer levertijden',
    },
    downloadTemplate: {
        en: 'Download template',
        fr: 'Télécharger le template',
        nl: 'Download template',
    },
    dataIntegrationCompleted: {
        en: 'Data integration completed',
        fr: 'Intégration des données terminée',
        nl: 'Gegevensintegratie voltooid',
    },
    downloadExample: {
        en: 'Download example',
        fr: 'Télécharger un example',
        nl: 'Download een voorbeeld',
    },
    addDefaultAddress: {
        en: 'Set as default address',
        fr: 'Définir comme adresse par défaut',
        nl: 'Instellen als standaardadres',
    },
    defaultOperatingCenter: {
        en: 'Default operating center',
        fr: "Centre d'opération par défaut",
        nl: 'Standaard bedrijfslocatie',
    },
    addContact: {
        en: 'Add contact',
        fr: 'Ajouter un contact',
        nl: 'Contact toevoegen',
    },
    addEmail: {
        en: 'Add email',
        fr: 'Ajouter un e-mail',
        nl: 'E-mail toevoegen',
    },
    editContact: {
        en: 'Edit contact',
        fr: 'Modifier le contact',
        nl: 'Bewerk contact',
    },
    removeDelivery: {
        en: 'Delete delivery',
        fr: 'Supprimer la livraison',
        nl: 'Levering verwijderen',
    },
    removeAddress: {
        en: 'Delete address',
        fr: "Supprimer l'adresse",
        nl: 'Adres verwijderen',
    },
    removeDepartment: {
        en: 'Delete department',
        fr: 'Supprimer le département',
        nl: 'Afdeling verwijderen',
    },
    removeDeliveryTime: {
        en: 'Delete delivery time',
        fr: 'Supprimer le délai de livraison',
        nl: 'Bezorgtijd verwijderen',
    },
    modalEditDeliveryTimeTitle: {
        en: 'Edit delivery time',
        fr: 'Modifier le délai de livraison',
        nl: 'Levertijd bewerken',
    },
    modalDeliveryTimeTitle: {
        en: 'Add a delivery time',
        fr: 'Ajouter un délai de livraison',
        nl: 'Voeg een bezorgtijd toe',
    },
    modalEditAccessibilityTitle: {
        en: 'Edit accessibility',
        fr: "Modifier l'accessibilité",
        nl: 'Toegankelijkheid bewerken',
    },
    modalAccessibilityTitle: {
        en: 'Add accessibility',
        fr: 'Ajouter une accessibilité',
        nl: 'Toegankelijkheid toevoegen',
    },
    removeAccessibility: {
        en: 'Remove accessibility',
        fr: "Supprimer l'accessibilité",
        nl: 'Toegankelijkheid verwijderen',
    },
    sureRemoveAccessibility: {
        en: 'Are you sure you want to remove accessibility? This action is final.',
        fr: "Êtes-vous certain de vouloir supprimer l'accessibilité? Cette action est définitive.",
        nl: 'Weet u zeker dat u de toegankelijkheid wilt verwijderen? Deze actie is definitief.',
    },
    removeDefaultAddress: {
        en: 'Remove default address',
        fr: "Supprimer l'adresse par défaut",
        nl: 'Standaard adres verwijderen',
    },
    delete: {
        en: 'Delete',
        fr: 'Supprimer',
        nl: 'Verwijderen',
    },
    cancelOrder: {
        en: 'Cancel the order',
        fr: 'Annuler la commande',
        nl: 'De bestelling annuleren',
    },
    upload: {
        en: 'Upload',
        fr: 'Téléverser',
        nl: 'uploaden',
    },
    download: {
        en: 'Download',
        fr: 'Télécharger',
        nl: 'Downloaden',
    },
    view: {
        en: 'View',
        fr: 'Visionner',
        nl: 'Visie',
    },
    modalDeleteTitle: {
        en: 'Delete the order',
        fr: 'Supprimer la commande',
        nl: 'Bestelling verwijderen',
    },
    sendTrackingLinkSubtitle: {
        en: 'Select the delivery for which you want to send the tracking link.\nWhen you select a delivery, the email address associated with the contact address automatically populates the 1st email address.\nYou can modify the email address. mail or add more.\nClick the Send button to send the tracking link.',
        fr: "Sélectionnez la livraison pour laquelle vous souhaitez envoyer le lien de suivi.\nLorsque vous sélectionnez une livraison, l'adresse e-mail associée à l'adresse contact renseigne automatiquement la 1ère adresse e-mail.\nVous pouvez modifier l'adresse e-mail ou en ajouter d'autres.\nCliquez sur le bouton Envoyer pour envoyer le lien de suivi.",
        nl: 'Selecteer de bezorging waarvoor u de trackinglink wilt verzenden.\nAls u een bezorging selecteert, wordt automatisch het eerste e-mailadres ingevuld met het e-mailadres dat is gekoppeld aan het contactadres.\nU kunt het e-mailadres wijzigen of meer toevoegen.\nKlik de knop Verzenden om de trackinglink te verzenden.',
    },
    modalDeleteSubtitle: {
        en: 'Are you sure you want to delete the order ? This action is definitive.',
        fr: 'Êtes-vous certains de vouloir supprimer la commande ? Cette action est définitive.',
        nl: 'Weet u zeker dat u de bestelling wilt verwijderen ? Deze actie is definitief.',
    },
    modalCancelTitle: {
        en: 'Cancel the order',
        fr: 'Annuler la commande',
        nl: 'De bestelling annuleren',
    },
    modalCancelSubtitle: {
        en: 'Are you sure you want to cancel the order ? This action is definitive.',
        fr: 'Êtes-vous certains de vouloir annuler la commande ? Cette action est définitive.',
        nl: 'Weet je zeker dat je de bestelling wilt annuleren? Deze actie is definitief.',
    },
    modalCancelNotPossibleSubtitle: {
        en: 'You can not cancell this order as it has been validated already. Please contact the operating center at this email : {{0}}.',
        fr: "Vous ne pouvez pas annuler cette commande car elle a déjà été validée. Veuillez contacter le centre d'exploitation à l'email suivant : {{0}}.",
        nl: 'U kunt deze bestelling niet annuleren omdat deze al is gevalideerd. Neem contact op met het operationele centrum via het volgende e-mailadres: {{0}}.',
    },
    modalNextStepTitle: {
        en: 'Validate to the next step of the order',
        fr: "Valider l'étape de la commande",
        nl: 'Valideer de bestelstap',
    },
    modalNextStepSubtitle: {
        en: 'Are you sure you want to take the next step ? This action is final.',
        fr: "Êtes-vous certain de vouloir passer à l'étape suivante ? Cette action est définitive.",
        nl: 'Weet je zeker dat je de volgende stap wilt zetten ? Deze actie is definitief.',
    },
    modalNextStepMessage: {
        en: 'Element has been successfully validated',
        fr: "L'élément a bien été validé",
        nl: 'Het element is gevalideerd',
    },
    modalDeleteMessage: {
        en: 'Element has been successfully deleted',
        fr: "L'élément a bien été supprimé",
        nl: 'Het element is succesvol verwijderd',
    },
    modalCancelOrderMessage: {
        en: 'Order creation has been successfully canceled',
        fr: 'La création de commande a bien été annulé',
        nl: 'Het maken van de bestelling is met succes geannuleerd',
    },
    modalCancelMessage: {
        en: 'Element has been successfully cancelled',
        fr: "L'élément a bien été annulé",
        nl: 'Het item is succesvol geannuleerd',
    },
    modalValidateTitle: {
        en: 'Validate the order',
        fr: 'Valider la commande',
        nl: 'Valideer de bestelling',
    },
    modalValidateSubtitle: {
        en: 'You are about to validate your order, you will not be able to change the information provided thereafter.',
        fr: 'Vous êtes sur le point sur le point de valider votre commande, vous ne pourrez plus changer les informations renseignées par la suite.',
        nl: 'U staat op het punt uw bestelling te valideren, u kunt de verstrekte informatie daarna niet meer wijzigen.',
    },
    modalValidateAfterSaveTitle: {
        en: 'Order Saved, do you want to validate it too ?',
        fr: 'Commande enregistrée, voulez-vous la valider également ?',
        nl: 'Bestelling opgeslagen, wilt u deze ook valideren ?',
    },
    modalValidateAfterSaveSubtitle: {
        en: 'Your order has been registered and you are about to validate it, you will not be able to change the information provided subsequently.',
        fr: 'Votre commande est enregistrée et vous êtes sur le point de la valider, vous ne pourrez plus changer les informations renseignées par la suite.',
        nl: 'Uw bestelling is geregistreerd en u staat op het punt deze te valideren, u kunt de verstrekte informatie daarna niet meer wijzigen.',
    },
    new: {
        en: 'New',
        fr: 'Nouveau',
        nl: 'Nieuw',
    },
    newInvoice: {
        en: 'New invoice',
        fr: 'Nouvelle facture',
        nl: 'Nieuwe factuur',
    },
    newDocument: {
        en: 'New document',
        fr: 'Nouveau document',
        nl: 'Nieuw document',
    },
    newUser: {
        en: 'New user',
        fr: 'Nouvel utilisateur',
        nl: 'Nieuwe gebruiker',
    },
    newClient: {
        en: 'New client',
        fr: 'Nouveau client',
        nl: 'Nieuwe klant',
    },
    newHomeArticle: {
        en: 'New novelty',
        fr: 'Nouvelle nouveauté',
        nl: 'Nieuwe nieuwigheid',
    },
    newHomeActuality: {
        en: 'New actuality',
        fr: 'Nouvelle actualité',
        nl: 'Nieuwe actualiteit',
    },
    newClientAccount: {
        en: 'New client account',
        fr: 'Nouveau compte client',
        nl: 'Nieuwe klantrekening',
    },
    modify: {
        en: 'Modify',
        fr: 'Modifier',
        nl: 'Bewerken',
    },
    yes: {
        en: 'Yes',
        fr: 'Oui',
        nl: 'Ja',
    },
    no: {
        en: 'No',
        fr: 'Non',
        nl: 'Neen',
    },
    deliveries: {
        en: 'Deliveries',
        fr: 'Livraisons',
        nl: 'Leveringen',
    },
    delivery: {
        en: 'Delivery',
        fr: 'Livraison',
        nl: 'Levering',
    },
    deliveryInstruction: {
        en: 'Delivery instructions',
        fr: 'Instructions livraison',
        nl: 'Levering instructies',
    },
    assiociatedDelivery: {
        en: 'Associated delivery',
        fr: 'Livraison associée',
        nl: 'Bijbehorende levering',
    },
    // pickupInstruction: {
    //     en: 'Instructions pickup appointment',
    //     fr: 'Instructions chargement',
    //     nl: 'Instructies voor het laden',
    // },
    comment: {
        en: 'Comment',
        fr: 'Commentaire',
        nl: 'Commentaar',
    },
    commentMeeting: {
        en: 'Comment related to the meeting',
        fr: 'Commentaire lié au rendez vous',
        nl: 'Opmerking met betrekking tot de afspraak',
    },
    commentOrder: {
        en: 'Invoice comment related to the order',
        fr: 'Commentaire facture sur la commande',
        nl: 'Algemene opmerking over de bestelling',
    },
    document: {
        en: 'Document',
        fr: 'Document',
        nl: 'Document',
    },
    insurance: {
        en: 'Insurance',
        fr: 'Assurance',
        nl: 'Verzekering',
    },
    invoice: {
        en: 'Invoice',
        fr: 'Facture',
        nl: 'Factuur',
    },
    invoiceDate: {
        en: 'Invoice date',
        fr: 'Date facture',
        nl: 'Factuurdatum',
    },
    intermediateWorkflowSteps: {
        en: 'Intermediate workflow steps',
        fr: 'Etapes du workflow intermédiaire',
        nl: 'Intermediaire workflow stappen',
    },
    packing: {
        en: 'Packing list',
        fr: 'Liste de colisage',
        nl: 'Paklijst',
    },
    page: {
        en: 'Page',
        fr: 'Page',
        nl: 'Pagina',
    },
    assuranceDocument: {
        en: 'Assurance',
        fr: 'Assurance',
        nl: 'Verzekerings',
    },
    customsDocument: {
        en: 'Customs',
        fr: 'Douane',
        nl: 'Douane',
    },
    ifSoYouWillBeAskedForAnAdditionalDocument: {
        en: 'If so, you will be asked for an additional document',
        fr: 'Si oui, un document supplémentaire vous sera demandé',
        nl: 'Dan wordt u om een ​​aanvullend document gevraagd ',
    },
    yourAccount: {
        en: 'Your account',
        fr: 'Votre compte',
        nl: 'Jouw rekening',
    },
    username: {
        en: 'Username',
        fr: 'Nom utilisateur',
        nl: 'Gebruikersnaam',
    },
    commandPortal: {
        en: 'Command portal',
        fr: 'Portail commande',
        nl: 'Bestelportaal',
    },
    allTransports: {
        en: 'My transports',
        fr: 'Mes transports',
        nl: 'Mijn transporten',
    },
    allOrders: {
        en: 'My orders',
        fr: 'Toutes mes commandes',
        nl: 'Mijn bestellingen',
    },
    allOrdersPickup: {
        en: 'My pickup orders',
        fr: 'Toutes mes commandes de chargement',
        nl: 'Al mijn laadopdrachten',
    },
    allOrdersDelivery: {
        en: 'My delivery orders',
        fr: 'Toutes mes commandes de livraison',
        nl: 'Al mijn bezorgbestellingen',
    },
    order: {
        en: 'Order',
        fr: 'Commande',
        nl: 'Bestelling',
    },
    orders: {
        en: 'Orders',
        fr: 'Commandes',
        nl: 'Bestellingen',
    },
    orderSettings: {
        en: 'Order settings',
        fr: 'Paramètres de commande',
        nl: 'Bestel instellingen',
    },
    myOrders: {
        en: 'My orders',
        fr: 'Mes commandes',
        nl: 'Mijn bestellingen',
    },
    myOrdersToValidate: {
        en: 'My orders to validate',
        fr: 'Mes commandes à valider',
        nl: 'Mijn bestellingen om te worden gevalideerd',
    },
    myOrdersToValidatePickup: {
        en: 'My pikcup orders to validate',
        fr: 'Mes commandes de chargement à valider',
        nl: 'Mijn laadopdrachten om te valideren',
    },
    myOrdersWaitingForConfirmation: {
        en: 'My orders waiting for confirmation',
        fr: 'Mes commandes en attente de validation',
        nl: 'Mijn bestellingen wachten op validatie',
    },
    myOrdersConfirmed: {
        en: 'My orders confirmed',
        fr: 'Mes commandes confirmées',
        nl: 'Mijn bevestigde bestellingen',
    },
    myOrdersConfirmedPickup: {
        en: 'My pickup orders confirmed',
        fr: 'Mes commandes de chargement confirmées',
        nl: 'Mijn bevestigde laadopdrachten',
    },
    myOrdersConfirmedDelivery: {
        en: 'My delivery orders confirmed',
        fr: 'Mes commandes de livraison confirmées',
        nl: 'Mijn bevestigde leveringsbestellingen',
    },
    myOrdersTreated: {
        en: 'My orders treated',
        fr: 'Mes commandes traitées',
        nl: 'Mijn bestellingen verwerkt',
    },
    myOrdersTreatedPickup: {
        en: 'My pickup orders treated',
        fr: 'Mes commandes de chargement traitées',
        nl: 'Mijn laadopdrachten verwerkt',
    },
    myOrdersTreatedDelivery: {
        en: 'My delivery orders treated',
        fr: 'Mes commandes de livraison traitées',
        nl: 'Mijn bezorgopdrachten verwerkt',
    },
    myOrdersCancelled: {
        en: 'My orders cancelled',
        fr: 'Mes commandes annulées',
        nl: 'Mijn geannuleerde bestellingen',
    },
    street: {
        en: 'Number/Street',
        fr: 'Numéro/Rue',
        nl: 'Nummer/Straat',
    },
    city: {
        en: 'City',
        fr: 'Ville',
        nl: 'Stad',
    },
    cityZipcode: {
        en: 'City / Zipcode',
        fr: 'Ville / Code Postal',
        nl: 'Stad / Postcode',
    },
    zipcode: {
        en: 'Zipcode',
        fr: 'Code Postal',
        nl: 'Postcode',
    },
    country: {
        en: 'Country',
        fr: 'Pays',
        nl: 'Land',
    },
    deliveryAndPickupAddresses: {
        en: 'Delivery and pickup addresses',
        fr: 'Adresses de livraison et de chargement',
        nl: 'Bezorg- en ophaaladressen',
    },
    contentOfTheOrder: {
        en: 'Content of the order',
        fr: 'Contenu de la commande',
        nl: 'Inhoud van de bestelling',
    },
    deliveryAddress: {
        en: 'Delivery address',
        fr: 'Adresse de livraison',
        nl: 'Bezorgadres',
    },
    deliveryAddressInfo: {
        en: 'Delivery address informations',
        fr: 'Informations adresse de livraison',
        nl: 'Afleveradres informatie',
    },
    pickupAddress: {
        en: 'Pickup address',
        fr: "Adresse d'enlèvement",
        nl: 'Ophaaladres',
    },
    pickupAddressInfo: {
        en: 'Pickup address informations',
        fr: 'Informations adresse de chargement',
        nl: 'Adresgegevens laden',
    },
    newPickupAddress: {
        en: 'New pickup address',
        fr: 'Nouvelle adresse de chargement',
        nl: 'Nieuw laadadres',
    },
    newDeliveryAddress: {
        en: 'New delivery address',
        fr: 'Nouvelle adresse de livraison',
        nl: 'Nieuw afleveradres',
    },
    validatePickupAddress: {
        en: 'Validate / modify pickup address',
        fr: "Valider / modifier l'adresse de chargement",
        nl: 'Valideer / wijzig het laadadres',
    },
    validateDeliveryAddress: {
        en: 'Validate / modify delivery address',
        fr: "Valider / modifier l'adresse de livraison",
        nl: 'Valideer / wijzig het afleveradres',
    },
    deliveryOperatingCenter: {
        en: 'Delivery operating center',
        fr: "Centre d'exploitation de livraison",
        nl: 'Levering operatie centrum',
    },
    pickupOperatingCenter: {
        en: 'Pickup operating center',
        fr: "Centre d'exploitation de chargement",
        nl: 'Afhaalcentrum',
    },
    step: {
        en: 'Step',
        fr: 'Etape',
        nl: 'Stap',
    },
    nextStep: {
        en: 'Next step',
        fr: 'Etape suivante',
        nl: 'Volgende stap',
    },
    productType: {
        en: 'Product type',
        fr: 'Type de produit',
        nl: 'Producttype',
    },
    productTypes: {
        en: 'Product types',
        fr: 'Types de produit',
        nl: 'Producttypes',
    },
    productTemperature: {
        en: 'Product temperature',
        fr: 'Température du produit',
        nl: 'Product temperatuur',
    },
    temperature: {
        en: 'Temperature',
        fr: 'Température',
        nl: 'Temperatuur',
    },
    temperatureIsRequired: {
        en: 'A temperature must be selected',
        fr: 'Une température doit être sélectionnée',
        nl: 'Er moet een temperatuur worden geselecteerd',
    },
    dateMustBeSelected: {
        en: 'Date must be selected',
        fr: 'La date doit être sélectionnée',
        nl: 'Datum moet worden geselecteerd',
    },
    aSupportMustBeSelected: {
        en: 'A support must be selected',
        fr: 'Un support doit être sélectionné',
        nl: 'Er moet een ondersteuning worden geselecteerd',
    },
    quantity: {
        en: 'Quantity',
        fr: 'Quantité',
        nl: 'Hoeveelheid',
    },
    productQuantity: {
        en: 'Product quantity',
        fr: 'Quantité du produit',
        nl: 'Product kwantiteit',
    },
    productWeight: {
        en: 'Product weight',
        fr: 'Poids du produit',
        nl: 'Productgewicht',
    },
    meeting: {
        en: 'Meeting',
        fr: 'Rendez-vous',
        nl: 'Afspraak',
    },
    meetingMandatory: {
        en: 'Meeting mandatory',
        fr: 'Rendez-vous obligatoire',
        nl: 'Afspraak verplicht',
    },
    isTheMeetingMandatoryInOrderToPickUpTheOrder: {
        en: 'Is the meeting mandatory in order to pick up the order ?',
        fr: 'Rdv obligatoire pour retirer la commande ?',
        nl: 'Is de afspraak verplicht om de bestelling op te halen ?',
    },
    isTheMeetingMandatoryInOrderToDeliverTheOrder: {
        en: 'Is the meeting mandatory in order to deliver the order ?',
        fr: 'Rdv obligatoire pour livrer la commande ?',
        nl: 'Is de bijeenkomst verplicht om de bestelling te bezorgen ?',
    },
    isVisible: {
        en: 'Is active ?',
        fr: 'Est actif ?',
        nl: 'Is actief ?',
    },
    isAddressVisibleAdmin: {
        fr: 'Cette adresse est visible par les clients',
        en: 'This address is visible by customers',
        nl: 'Dit adres is zichtbaar voor klanten',
    },
    isAddressVisible: {
        en: 'This address is recurrent, I want to save it in my address book',
        fr: "Cette adresse est récurrente, je souhaite l'enregistrer dans mon carnet d'adresse",
        nl: 'Dit adres is terugkerend, ik wil het opslaan in mijn adresboek',
    },
    isValidated: {
        en: 'Is validated?',
        fr: 'Est validé ?',
        nl: 'Is het gevalideerd?',
    },
    isValidateExploitationRequired: {
        en: 'V. exploitation required ?',
        fr: 'V. exploitation requise ?',
        nl: 'V. exploitatie vereist ?',
    },
    isAddressValidationRequired: {
        en: 'V. address required ?',
        fr: 'V. adresse requise ?',
        nl: 'V. adres vereist ?',
    },
    isPickupAddress: {
        en: 'Is pickup address',
        fr: 'Adresse de chargement',
        nl: 'Is ophaaladres',
    },
    isDeliveryAddress: {
        en: 'Is delivery address',
        fr: 'Adresse de livraison',
        nl: 'Is afleveradres',
    },
    documents: {
        en: 'Documents',
        fr: 'Documents',
        nl: 'Documenten',
    },
    deliveryReference: {
        en: 'Delivery reference',
        fr: 'Référence livraison',
        nl: 'Leveringsreferentie',
    },
    pickupReference: {
        en: 'Pickup reference',
        fr: 'Référence chargement',
        nl: 'Referentie laden',
    },
    clientName: {
        en: 'Client name',
        fr: 'Nom du client',
        nl: 'Klantnaam',
    },
    companyName: {
        en: 'Company name',
        fr: 'Nom de la société',
        nl: 'Bedrijfsnaam',
    },
    setCompanyOperational: {
        en: 'Set company operational',
        fr: 'Mettre la société en exploitation',
        nl: 'Zet het bedrijf in bedrijf',
    },
    setCompanyNonOperational: {
        en: 'Set company non operational',
        fr: 'Mettre la société hors exploitation',
        nl: 'Zet het bedrijf niet in bedrijf',
    },
    complements: {
        en: 'Complements',
        fr: 'Compléments',
        nl: 'Aanvullingen',
    },
    doYouNeedAnInsurance: {
        en: 'Do you need an insurance ?',
        fr: "Avez vous besoin d'une assurance ?",
        nl: 'Heeft u een verzekering nodig ?',
    },
    modalAddressDefaultTitle: {
        en: 'Add the address of the client {{clientName}}',
        fr: "Ajouter l'adresse du client {{clientName}}",
        nl: 'Voeg het adres van de klant {{clientName}} toe',
    },
    addressWillBeControlledByCompany: {
        en: 'All new addresses (or modifications) will be checked in advance of your order by the {{companyName}} team.',
        fr: 'Toute nouvelle adresse (ou modification) sera contrôlée en amont de votre commande par l’équipe {{companyName}}.',
        nl: 'Alle nieuwe adressen (of wijzigingen) worden in de voorafgaande bestelling gecontroleerd door het {{companyName}}-team.',
    },
    addressValidation: {
        en: 'Address validation',
        fr: "Validation de l'adresse",
        nl: 'Adresvalidatie',
    },
    operatorValidation: {
        en: 'Operator validation',
        fr: "Validation de l'opérateur",
        nl: 'Operatorvalidatie',
    },
    workflowStep: {
        en: 'Workflow step',
        fr: 'Etape du workflow',
        nl: 'Workflowstap',
    },
    modalAddressValidationTitle: {
        en: 'Validate / modify address',
        fr: "Valider / modifier l'adresse",
        nl: 'Valideer / wijzig het adres',
    },
    modalAddressTitle: {
        en: 'Add a new address',
        fr: 'Ajouter une nouvelle adresse',
        nl: 'Voeg een nieuw adres toe',
    },
    modalValidAddressTitle: {
        en: 'Valid an address',
        fr: 'Valider une adresse',
        nl: 'Een adres valideren',
    },
    modalEditAddressTitle: {
        en: 'Edit an address',
        fr: 'Modifier une adresse',
        nl: 'Een adres wijzigen',
    },
    modalEditLogisticConstraintTitle: {
        en: 'Edit a logistic constraint',
        fr: 'Modifier la contrainte logistique',
        nl: 'Een logistieke beperking bewerken',
    },
    modalEditDeliveryTypeTitle: {
        en: 'Edit a delivery type',
        fr: 'Modifier le type de livraison',
        nl: 'Een leveringstype bewerken',
    },
    modalEditDepartmentTitle: {
        en: 'Edit department',
        fr: 'Modifier le département',
        nl: 'Verander afdeling',
    },
    modalDepartmentTitle: {
        en: 'Add a department',
        fr: 'Ajouter un département',
        nl: 'Voeg een afdeling toe',
    },
    modalDeliveryTypeTitle: {
        en: 'Add a delivery type',
        fr: 'Ajouter un type de livraison',
        nl: 'Voeg een leveringstype toe',
    },
    modalLogisticConstraintTitle: {
        en: 'Add a logistic constraint',
        fr: 'Ajouter une contrainte logistique',
        nl: 'Voeg een logistieke beperking toe',
    },
    modalPickupInstructionTitle: {
        en: 'Pick up instruction',
        fr: "Instruction d'enlèvement",
        nl: 'Ophalen Instructie',
    },
    modalDeliveryInstructionTitle: {
        en: 'Delivery instruction',
        fr: 'Instruction de livraison',
        nl: 'Leveringinstructie',
    },
    writePickupInstruction: {
        en: 'Write a pickup instruction',
        fr: "Écrire une instruction d'enlèvement",
        nl: 'Schrijf een ophaalinstructie',
    },
    writeDeliveryInstruction: {
        en: 'Write a delivery instruction',
        fr: 'Écrire une instruction de livraison',
        nl: 'Schrijf een leveringinstructie',
    },
    modalEditContactTitle: {
        en: 'Edit a contact',
        fr: 'Modifier un contact',
        nl: 'Een contact bewerken',
    },
    modalClientContactTitle: {
        en: 'Add a contact for the address of the client {{clientName}}',
        fr: "Ajouter un contact pour l'address du client {{clientName}}",
        nl: 'Voeg een contact toe voor het adres van de klant {{clientName}}',
    },
    modalContactTitle: {
        en: 'Add a new contact',
        fr: 'Ajouter un nouveau contact',
        nl: 'Een nieuw contact toevoegen',
    },
    modalArticleTitle: {
        en: 'Add a new article',
        fr: 'Ajouter un nouvel article',
        nl: 'Voeg een nieuw artikel toe',
    },
    modalEditArticleTitle: {
        en: 'Edit an article',
        fr: 'Modifier un article',
        nl: 'Een artikel bewerken',
    },
    modalEditComfourDataTitle: {
        en: 'Edit COMFOUR delivery information',
        fr: 'Modifier les informations de livraison COMFOUR',
        nl: 'COMFOUR-bezorgingsinformatie wijzigen',
    },
    modalComfourDataTitle: {
        en: 'Add Leroy Merlin order information',
        fr: 'Ajouter les informations de la commande Leroy Merlin',
        nl: 'Leroy Merlin bestelinformatie toevoegen',
    },
    modalContactSubtitle: {
        en: 'Enter contact information',
        fr: 'Entrez les informations de contact',
        nl: 'Voer contactgegevens in',
    },
    modalAddressSubtitle: {
        en: 'Enter address information',
        fr: "Saisir les informations de l'adresse",
        nl: 'Adresgegevens invoeren',
    },
    modalAddressSubtitleDelivery: {
        en: 'Enter the delivery address information',
        fr: "Saisir les informations de l'adresse de livraison",
        nl: 'Voer de gegevens van het afleveradres in',
    },
    modalAddressSubtitleComment: {
        en: 'Enter address instructions',
        fr: "Saisir les instructions de l'adresse",
        nl: 'Voer adresinstructies in',
    },
    modalAddressSubtitlePickup: {
        en: 'Enter the pickup address information',
        fr: "Saisir les informations de l'adresse de chargement",
        nl: 'Voer de gegevens van het ophaaladres in',
    },
    deleteDocument: {
        en: 'Delete the document',
        fr: 'Supprimer le document',
        nl: 'verwijder document',
    },
    deleteDocumentSub: {
        en: 'Are you sure you want to delete the document ? This action is definitive.',
        fr: 'Êtes-vous certains de vouloir supprimer le document ? Cette action est définitive.',
        nl: 'Weet u zeker dat u het document wilt verwijderen ? Deze actie is definitief.',
    },
    noResultFound: {
        en: 'No result found',
        fr: 'Aucun résultat trouvé',
        nl: 'Geen resultaat gevonden',
    },
    pleaseDoASearch: {
        en: 'Please do a search',
        fr: 'Veuillez faire une recherche',
        nl: 'Voer een zoekopdracht uit',
    },
    createdThe: {
        en: 'Created the',
        fr: 'Créé le',
        nl: 'Creëerde het',
    },
    creation: {
        en: 'Creation',
        fr: 'Création',
        nl: 'Creëren',
    },
    createdBy: {
        en: 'Created by',
        fr: 'Créé par',
        nl: 'Gemaakt door',
    },
    anErrorHasOccurred: {
        en: 'An error has occurred',
        fr: 'Une erreur est survenue',
        nl: 'Er is een fout opgetreden ',
    },
    error: {
        en: 'Error',
        fr: 'Erreur',
        nl: 'Vergissing',
    },
    theElementHasBeenAdded: {
        en: 'The element has been added',
        fr: "L'élément a bien été ajouté",
        nl: 'Het element is toegevoegd',
    },
    theElementhasBeenDeleted: {
        en: 'The element has been deleted',
        fr: "L'élément a bien été supprimé",
        nl: 'Het element is verwijderd',
    },
    theElementhasBeenCancelled: {
        en: 'The element has been cancelled',
        fr: "L'élément a bien été annulé",
        nl: 'Het item is succesvol geannuleerd',
    },
    theElementhasBeenUpdated: {
        en: 'The element has been updated',
        fr: "L'élément a bien été mis à jour",
        nl: 'Het element is bijgewerkt',
    },
    youAreNotAllowedToDoThisAction: {
        en: 'You are not allowed to do this action',
        fr: "Vous n'êtes pas autorisé à faire cette action",
        nl: 'U mag deze handeling niet uitvoeren',
    },
    itemNotFound: {
        en: 'Item not found',
        fr: "L'élément n'a pas été trouvé",
        nl: 'Item niet gevonden',
    },
    content: {
        en: 'Content',
        fr: 'Marchandises',
        nl: 'Inhoud',
    },
    comments: {
        en: 'Comments',
        fr: 'Commentaires',
        nl: 'Opmerkingen',
    },
    merchandise: {
        en: 'Merchandise',
        fr: 'Marchandise',
        nl: 'Handelswaar',
    },
    toAddDocumentsYouMustFirstSaveYourOrder: {
        en: 'To add documents, you must first save your order',
        fr: "Pour ajouter des documents, vous devez d'abord enregistrer votre commande",
        nl: 'Om documenten toe te voegen, moet u uw bestelling eerst opslaan',
    },
    administration: {
        en: 'Administration',
        fr: 'Administration',
        nl: 'Administratie',
    },
    users: {
        en: 'Users',
        fr: 'Utilisateurs',
        nl: 'Gebruikers',
    },
    user: {
        en: 'User',
        fr: 'Utilisateur',
        nl: 'Gebruiker',
    },
    operatingCenters: {
        en: 'Operating centers',
        fr: 'Exploitations',
        nl: 'Boerderijen',
    },
    operatingCenter: {
        en: 'Operating center',
        fr: 'Exploitation',
        nl: 'Operatie',
    },
    clients: {
        en: 'Clients',
        fr: 'Clients',
        nl: 'Klanten',
    },
    currentClient: {
        en: 'Current client',
        fr: 'Client actuel',
        nl: 'Huidige klant',
    },
    client: {
        en: 'Client',
        fr: 'Client',
        nl: 'Klant',
    },
    clientAccounts: {
        en: 'Client Accounts',
        fr: 'Comptes clients',
        nl: 'Klantaccounts',
    },
    clientAccount: {
        en: 'Client Account',
        fr: 'Compte client',
        nl: 'Klantaccount',
    },
    imagineCode: {
        en: 'Imagine code',
        fr: 'Code Imagine',
        nl: 'Imagine code',
    },
    tmsCode: {
        en: 'Tms code',
        fr: 'Code tms',
        nl: 'Tms code',
    },
    tmsTypeCode: {
        en: 'Tms type code',
        fr: 'Code type tms',
        nl: 'Tms type code',
    },
    email: {
        en: 'Email',
        fr: 'Email',
        nl: 'Email',
    },
    emails: {
        en: 'Emails',
        fr: 'Emails',
        nl: 'Emails',
    },
    password: {
        en: 'Password',
        fr: 'Mot de passe',
        nl: 'Wachtwoord',
    },
    confirmPassword: {
        en: 'Confirm password',
        fr: 'Confirmer mot de passe',
        nl: 'bevestig wachtwoord',
    },
    memberOfMousset: {
        en: 'Member of Mousset ?',
        fr: 'Membre de Mousset ?',
        nl: 'Lid van Mousset ?',
    },
    yourPasswordHasBeenReset: {
        en: 'Your password has been reset',
        fr: 'Votre mot de passe à bien été réinitialisé',
        nl: 'Je wachtwoord is gereset',
    },
    signup: {
        en: 'Signup',
        fr: "S'inscrire",
        nl: 'Aanmelden',
    },
    passwordForgotten: {
        en: 'Password forgotten ?',
        fr: 'Mot de passe oublié ?',
        nl: 'Wachtwoord vergeten ?',
    },
    requestPassword: {
        en: 'Reset password',
        fr: 'Réinitialiser le mot de passe',
        nl: 'Wachtwoord opnieuw instellen',
    },
    requestPasswordSent: {
        en: 'Your password reset request has been taken into account. You will receive an email shortly.',
        fr: 'Votre demande de réinitialisation de mot de passe a été pris en compte. Vous recevrez prochainement un email.',
        nl: 'Er is rekening gehouden met uw verzoek voor het opnieuw instellen van uw wachtwoord. U ontvangt binnenkort een e-mail.',
    },
    roles: {
        en: 'Roles',
        fr: 'Rôles',
        nl: 'Rollen',
    },
    company: {
        en: 'Company',
        fr: 'Société',
        nl: 'Bedrijf',
    },
    logo: {
        en: 'Logo',
        fr: 'Logo',
        nl: 'Logo',
    },
    companies: {
        en: 'Companies',
        fr: 'Sociétés',
        nl: 'Bedrijven',
    },
    selectionOfACompany: {
        en: 'Selection of a company',
        fr: "Selection d'une société",
        nl: 'Selectie van een bedrijf',
    },
    references: {
        en: 'References',
        fr: 'Références',
        nl: 'Referenties',
    },
    clientReference: {
        en: 'Client reference',
        fr: 'Référence client',
        nl: 'Klantreferentie',
    },
    clientReferenceInfo: {
        en: 'Client reference',
        fr: 'Saisir la référence qui sera affichée sur la facture. Cette référence doit être unique et donc différente des dernières commandes.',
        nl: 'Klantreferentie',
    },
    name: {
        en: 'Name',
        fr: 'Nom',
        nl: 'Naam',
    },
    abbreviation: {
        en: 'Abbreviation',
        fr: 'Abréviation',
        nl: 'Afkorting',
    },
    isComfourDataRequired: {
        en: 'Is Comfour data required ?',
        fr: 'Données Comfour requises ?',
        nl: 'Zijn Comfour-gegevens vereist ?',
    },
    isRdvRequired: {
        en: 'Is meeting required ?',
        fr: 'Rdv requis ?',
        nl: 'Is een afspraak vereist ?',
    },
    isOtNumberRequired: {
        en: 'Is OT number required ?',
        fr: 'Numéro OT requis ?',
        nl: 'Is OT-nummer vereist ?',
    },
    deliveryTypes: {
        en: 'Delivery types',
        fr: 'Types de livraison',
        nl: 'Leveringstypes',
    },
    deliveryConfirmed: {
        fr: 'Votre demande a été confirmée.',
        en: 'Your request has been confirmed.',
        nl: 'Uw verzoek is bevestigd.',
    },
    deliveryWaiting: {
        fr: "Votre demande est en attente d'envoi.",
        en: 'Your request is waiting to be sent.',
        nl: 'Uw verzoek wacht om te worden verzonden.',
    },
    deliverySent: {
        fr: 'Votre demande est en attente de confirmation.',
        en: 'Your request is waiting for confirmation.',
        nl: 'Uw verzoek wacht op bevestiging.',
    },
    deliveryNotConfirmed: {
        fr: "Votre demande n'a pas été confirmée",
        en: 'Your request has not been confirmed',
        nl: 'Uw verzoek is niet bevestigd',
    },
    clientCode: {
        en: 'Client code',
        fr: 'Code client',
        nl: 'Klantencode',
    },
    isClientRisky: {
        en: 'Is the client at risk ?',
        fr: 'Le client est-il à risque ?',
        nl: 'Loopt de cliënt gevaar ?',
    },
    canCreateOrder: {
        en: 'Can create an order?',
        fr: 'Peut créer une commande ?',
        nl: 'Kunt u een bestelling maken?',
    },
    isFootageInformable: {
        en: 'Is the footage informable ?',
        fr: 'Le métrage est-il renseignable ?',
        nl: 'Kan de beeldverhouding worden geïnformeerd ?',
    },
    calcFootage: {
        en: 'Calc footage ?',
        fr: 'Calcul métrage ?',
        nl: 'Bereken beeldverhouding ?',
    },
    isOrderSendingEmailAutomatic: {
        en: 'Tracking link sent automatically?',
        fr: 'Lien de suivi envoyé automatiquement ?',
        nl: 'Trackinglink automatisch verzonden?',
    },
    confirmationEmailEntry: {
        en: 'Confirmation Email Entry',
        fr: 'Confirmation Mail Saisie',
        nl: 'Bevestiging per e-mailinvoer',
    },
    labelFormat: {
        en: 'Default label format',
        fr: 'Format des étiquettes par défaut',
        nl: 'Standaard labelformaat',
    },
    contractor: {
        en: 'Contractor',
        fr: "Donneur d'ordre",
        nl: 'Aannemer',
    },
    refDo: {
        fr: 'REF DO',
        en: 'REF DO',
        nl: 'REF DO',
    },
    firstname: {
        en: 'Firstname',
        fr: 'Prénom',
        nl: 'Voornaam',
    },
    storeNumber: {
        en: 'Store number',
        fr: 'Numéro du magasin',
        nl: 'Winkelnummer',
    },
    clientNumber: {
        en: 'Client number',
        fr: 'Numéro client',
        nl: 'Client nummer',
    },
    store: {
        en: 'Store',
        fr: 'Magasin',
        nl: 'Winkel',
    },
    orderNumber: {
        en: 'Allegro number',
        fr: 'Numéro Allegro',
        nl: 'Allegro nummer',
    },
    lastname: {
        en: 'Lastname',
        fr: 'Nom',
        nl: 'Achternaam',
    },
    administrationEntry: {
        en: 'Administration entry',
        fr: 'Saisie administration',
        nl: 'Administratie-invoer',
    },
    allAdministrations: {
        en: 'All {{0}}',
        fr: 'Tous les {{0}}',
        nl: 'Alle {{0}}',
    },
    allClientAccounts: {
        en: 'Client accounts',
        fr: 'Comptes clients',
        nl: 'Klantaccounts',
    },
    profiles: {
        en: 'Profiles',
        fr: 'Profils',
        nl: 'Profielen',
    },
    profile: {
        en: 'Profile',
        fr: 'Profil',
        nl: 'Profiel',
    },
    addressType: {
        en: 'Address type',
        fr: "Type d'adresse",
        nl: 'Adrestype',
    },
    modalDeleteElementTitle: {
        en: 'Delete {{0}}',
        fr: 'Supprimer {{0}}',
        nl: 'Bestelling {{0}}',
    },
    modalDeleteElementSubtitle: {
        en: 'Are you sure you want to delete the {{0}} ? This action is definitive.',
        fr: 'Êtes-vous certains de vouloir supprimer le/la {{0}} ? Cette action est définitive.',
        nl: 'Weet u zeker dat u de bestelling wilt {{0}} ? Deze actie is definitief.',
    },
    modalDeleteElementTitleAddress: {
        en: 'Delete address',
        fr: 'Supprimer cette adresse',
        nl: 'Dit adres verwijderen',
    },
    modalDeleteElementSubtitleAddress: {
        en: 'Are you sure you want to delete this address ? This action is definitive.',
        fr: 'Êtes-vous certains de vouloir supprimer cette adresse ? Cette action est définitive.',
        nl: 'Weet je zeker dat je dit adres wilt verwijderen? Deze actie is definitief.',
    },
    fields: {
        en: 'Fields',
        fr: 'Champs',
        nl: 'Velden',
    },
    information: {
        en: 'Information',
        fr: 'Informations',
        nl: 'Informatie',
    },
    addresses: {
        en: 'Addresses',
        fr: 'Adresses',
        nl: 'Adressen',
    },
    addressesToBeValidated: {
        en: 'Addresses to be validated',
        fr: 'Adresses à valider',
        nl: 'Adressen om te valideren',
    },
    departments: {
        en: 'Departments',
        fr: 'Départements',
        nl: 'Afdelingen',
    },
    department: {
        en: 'Department',
        fr: 'Département',
        nl: 'Afdeling',
    },
    platform: {
        en: 'Platform',
        fr: 'Plateforme',
        nl: 'Platform',
    },
    platforms: {
        en: 'Platforms',
        fr: 'Plateformes',
        nl: 'Platforms',
    },
    address: {
        en: 'Address',
        fr: 'Adresse',
        nl: 'Adres',
    },
    clientAddress: {
        fr: 'Adresse du client',
        en: 'Client address',
        nl: 'Klantadres',
    },
    deliveryAddresses: {
        en: 'Delivery addresses',
        fr: 'Adresses de livraison',
        nl: 'Afleveradressen',
    },
    pickupAddresses: {
        en: 'Pickup addresses',
        fr: 'Adresses de chargement',
        nl: 'Ophaaladressen',
    },
    registrationNumber: {
        en: 'Registration number',
        fr: 'Matricule',
        nl: 'Registratie nummer',
    },
    validatedClient: {
        en: 'Client validation',
        fr: 'Validation client',
        nl: 'Klantvalidatie',
    },
    validatedCommand: {
        en: 'Company validation',
        fr: 'Validation société',
        nl: 'Bedrijfsvalidatie',
    },
    validatedAddressPickup: {
        en: 'Pickup address',
        fr: 'Adresse de chargement',
        nl: 'Adres laden',
    },
    validatedAddressDelivery: {
        en: 'Delivery address',
        fr: 'Adresse de livraison',
        nl: 'Bezorgadres',
    },
    pleaseSelectACompanyFirst: {
        en: 'Please select a company first',
        fr: 'Veuillez selectionner une société en premier',
        nl: 'Selecteer eerst een bedrijf',
    },
    orderHasBeenAdded: {
        en: 'The command has been succesfully added. Once the documents have been added, remember to validate the order.',
        fr: 'La commande a bien été ajoutée. Une fois les documents renseignés, pensez à valider la commande.',
        nl: 'De opdracht is succesvol toegevoegd. Vergeet niet om de bestelling te valideren zodra de documenten zijn ingevuld.',
    },
    rejected: {
        en: 'Rejected',
        fr: 'Rejeté',
        nl: 'Afgewezen',
    },
    orderRejectedBannerText: {
        en: 'An error has occurred, please contact the {{companyName}} teams at the following email address: {{mail}}.',
        fr: "Une erreure s'est produite, Merci de contacter les équipes {{companyName}} à l'addresse mail suivante: {{mail}}.",
        nl: 'Er is een fout opgetreden, neem contact op met het {{companyName}} team via het volgende e-mailadres: {{mail}}.',
    },
    startTime: {
        en: 'Start time',
        fr: 'Horaire de début',
        nl: 'starttijd',
    },
    endTime: {
        en: 'End time',
        fr: 'Horaire de fin',
        nl: 'eindtijd',
    },
    dates: {
        en: 'Dates',
        fr: 'Dates',
        nl: 'Datums',
    },
    date: {
        en: 'Date',
        fr: 'Date',
        nl: 'Datum',
    },
    dateFormat: {
        en: 'dd/mm/yyyy',
        fr: 'jj/mm/yyyy',
        nl: 'dd/mm/jjjj',
    },
    pickupDate: {
        en: 'Pickup date',
        fr: "Date d'enlèvement",
        nl: 'Datum van laden',
    },
    endPickupDate: {
        en: 'End pickup date',
        fr: 'Date de fin de chargement',
        nl: 'Einde laaddatum',
    },
    pickupDateHour: {
        en: 'Start pickup hour',
        fr: "Début d'heure de chargement",
        nl: 'Begin van laadtijd',
    },
    endPickupDateHour: {
        en: 'End pickup hour',
        fr: "Fin d'heure de chargement",
        nl: 'Einde laadtijd',
    },
    deliveryDateHour: {
        en: 'Start delivery hour',
        fr: "Début d'heure de livraison",
        nl: 'Begin levertijd',
    },
    endDeliveryDateHour: {
        en: 'End delivery hour',
        fr: "Fin d'heure de livraison",
        nl: 'Einde levertijd',
    },
    deliveryDate: {
        en: 'Delivery date',
        fr: 'Date de livraison',
        nl: 'Aflevertijd',
    },
    endDeliveryDate: {
        en: 'End delivery date',
        fr: 'Date de fin de livraison',
        nl: 'Einddatum levering',
    },
    timeDeliveryDate: {
        en: 'Estimated delivery time',
        fr: 'Temps de livraison estimé',
        nl: 'Geschatte Leveringstijd',
    },
    estimated: {
        en: 'Estimated',
        fr: 'Estimé',
        nl: 'Geschat',
    },
    picked: {
        en: 'Picked',
        fr: 'Enlevé',
        nl: 'Opgehaald',
    },
    timePickupDate: {
        en: 'Estimated pickup time',
        fr: 'Temps de chargement estimé',
        nl: 'Geschatte oplaadtijd',
    },
    pleaseAddTheFile: {
        en: 'Please add the file "{{0}}".',
        fr: 'Veuillez ajouter le fichier "{{0}}".',
        nl: 'Voeg het bestand "{{0}}" toe.',
    },
    addFileOptionnal: {
        en: 'The file "{{0}}" is optionnal.',
        fr: 'Le fichier "{{0}}" est optionnel.',
        nl: 'Het bestand "{{0}}" is optioneel.',
    },
    type: {
        en: 'Type',
        fr: 'Type',
        nl: 'Type',
    },
    noFileFound: {
        en: 'No file found',
        fr: 'Aucun fichier trouvé',
        nl: 'Geen bestanden gevonden',
    },
    dragYourDocument: {
        en: 'Drag your document',
        fr: 'Glisser votre document',
        nl: 'Sleep uw document',
    },
    or: {
        en: 'or',
        fr: 'ou',
        nl: 'of',
    },
    clickHere: {
        en: 'click here',
        fr: 'cliquer ici',
        nl: 'llik hier',
    },
    toSelectAFile: {
        en: 'to select a file',
        fr: 'pour sélectionner un fichier',
        nl: 'om een ​​bestand te selecteren',
    },
    documentType: {
        en: 'Document type',
        fr: 'Type de document',
        nl: 'Documenttype',
    },
    nameEn: {
        en: 'Name (en)',
        fr: 'Nom (en)',
        nl: 'Naam (en)',
    },
    nameFr: {
        en: 'Name (fr)',
        fr: 'Nom (fr)',
        nl: 'Naam (fr)',
    },
    nameNl: {
        en: 'Name (nl)',
        fr: 'Nom (nl)',
        nl: 'Naam (nl)',
    },
    countries: {
        en: 'Countries',
        fr: 'Pays',
        nl: 'Lands',
    },
    countriesMandatory: {
        en: 'Mandatory countries',
        fr: 'Pays obligatoires',
        nl: 'Verplichte landen',
    },
    countriesOptionnal: {
        en: 'Optional countries',
        fr: 'Pays optionnels',
        nl: 'Optionele landen',
    },
    files: {
        en: 'Files',
        fr: 'Fichiers',
        nl: 'Bestanden',
    },
    fileSettings: {
        en: 'File settings',
        fr: 'Paramètres des fichiers',
        nl: 'Bestandsinstellingen',
    },
    activityCodes: {
        en: 'Activity codes',
        fr: 'Codes d’activité',
        nl: 'Activiteitscodes',
    },
    activityCode: {
        en: 'Activity code',
        fr: 'Code d’activité',
        nl: 'Activiteitscode',
    },
    fileSetting: {
        en: 'File setting',
        fr: 'Param. du fichier',
        nl: 'Bestandsinstelling',
    },
    fileNotRequired: {
        en: 'File not required',
        fr: 'Fichier non requis',
        nl: 'Bestand niet vereist',
    },
    docRequiredForCountry: {
        en: 'The document will be mandatory on orders with the country of delivery or pick-up in the countries selected',
        fr: 'Le document sera obligatoire sur les commandes ayant le pays de livraison ou de ramassage dans les pays sélectionné',
        nl: 'Het document is verplicht bij bestellingen met het land van levering of afhaling in de geselecteerde landen',
    },
    docOptionnalForCountry: {
        en: 'The document will be optional on orders with the country of delivery or pick-up in the countries selected',
        fr: 'Le document sera optionnel sur les commandes ayant le pays de livraison ou de ramassage dans les pays sélectionné',
        nl: 'Het document is optioneel voor bestellingen met het land van levering of afhaling in de geselecteerde landen',
    },
    commentOrderInfo: {
        en: 'This comment will be displayed on the CMR. (Example: Supplier name)',
        fr: 'Ce commentaire sera affiché sur la CMR. (Exemple : Nom du Fournisseur)',
        nl: 'Deze opmerking wordt weergegeven op de CMR. (Voorbeeld: Leveranciersnaam)',
    },
    thisAddressMustBeVerified: {
        en: 'This address must be verified by the company before validating the order.',
        fr: 'Cette adresse doit être vérifiée par la société avant validation de la commande.',
        nl: 'Dit adres moet door het bedrijf worden geverifieerd voordat de bestelling wordt gevalideerd.',
    },
    thisAddressisSurtaxed: {
        en: 'This address is surtaxed.',
        fr: 'Cette adresse est surtaxée.',
        nl: 'Dit adres is belast.',
    },
    editInstructions: {
        en: 'Edit instructions',
        fr: 'Modifier les instructions',
        nl: 'Instructies bewerken',
    },
    timeOrder: {
        en: 'Time for order',
        fr: 'Heure pour commande',
        nl: 'Tijd voor bestelling',
    },
    yourOrderMustBePlacedBefore: {
        en: 'Your order must be placed before <b>{{0}}</b> (excluding weekends and holidays) if you want it to be picked up the next day.',
        fr: "Votre commande doit être passée avant <b>{{0}}</b> (hors Weekends et jours fériés) si vous voulez qu'elle soit ramassée le jour suivant.",
        nl: 'Uw bestelling moet vóór <b>{{0}}</b> (exclusief weekends en feestdagen) worden geplaatst als u wilt dat deze de volgende dag wordt opgehaald.',
    },
    warningFile: {
        en: 'The packing list is mandatory for the completion of your transport order. Please attach it to your transport request and put it on the pallet(s) of your merchandise.',
        fr: 'Le bon de livraison est obligatoire pour la réalisation de votre ordre de transport. Veuillez le joindre à votre demande de transport et le mettre sur la ou les palettes de votre marchandise.',
        nl: 'De pakbon is verplicht voor het afronden van uw transportopdracht. Gelieve deze bij uw transportaanvraag te voegen en op de pallet(s) van uw handelswaar te plaatsen.',
    },
    amountInsurrance: {
        en: 'Amount to cover',
        fr: 'Montant à couvrir',
        nl: 'Te dekken bedrag',
    },
    labelAddress: {
        en: 'Address name',
        fr: "Nom de l'adresse",
        nl: 'Adres naam',
    },
    phoneNumber: {
        en: 'Phone number',
        fr: 'Numéro de téléphone',
        nl: 'Telefoonnummer',
    },
    phoneNumber2: {
        en: 'Other phone number',
        fr: 'Autre numéro de téléphone',
        nl: 'Andere telefoonnummer',
    },
    pleaseEnterYourLogo: {
        en: 'Please add your company logo',
        fr: 'Veuillez ajouter le logo de votre société',
        nl: 'Voeg het logo van uw bedrijf toe',
    },
    whyEnterYourLogo: {
        en: 'Your company logo will be displayed on the portal as well as on the various transport documents.',
        fr: 'Le logo de votre société sera affiché sur le portail ainsi que sur les différents documents de transport.',
        nl: 'Het logo van uw bedrijf wordt weergegeven op het portaal en op de verschillende transportdocumenten.',
    },
    pleaseEnterYourPhoneNumber: {
        en: 'Please enter your personal information',
        fr: 'Veuillez renseigner vos informations personnelles',
        nl: 'Voer uw persoonlijke gegevens in',
    },
    addLater: {
        en: 'Add later',
        fr: 'Ajouter plus tard',
        nl: 'Voeg later toe',
    },
    inOrderPleaseSpecifyInformationBelow: {
        en: 'In order to be able to contact you in case of problems, please specify the information below:',
        fr: 'Afin de pouvoir vous contacter en cas de problemes, veuillez préciser les informations si dessous:',
        nl: 'Om bij problemen contact met u op te kunnen nemen, verzoeken wij u onderstaande gegevens te vermelden:',
    },
    deliveryClientReference: {
        en: 'Delivery Client Ref',
        fr: 'Livraison Réf Client',
        nl: 'Levering Klantreferentie',
    },
    pickupClientReference: {
        en: 'Pickup Client Ref',
        fr: 'Chargement Réf Client',
        nl: 'Klantreferentie laden',
    },
    theElementhasBeenDuplicated: {
        en: 'The element has been duplicated',
        fr: "L'élément a bien été dupliqué",
        nl: 'Het element is duplicaat',
    },
    duplicate: {
        en: 'Duplicate',
        fr: 'Dupliquer',
        nl: 'Duplicaat',
    },
    doYouWantToCreateANewOrderFromTheDataOfThisOne: {
        en: 'Do you want to create a new order from the data of this one?',
        fr: 'Voulez-vous créer une nouvelle commande à partir des données de celle-ci ?',
        nl: 'Wil je een nieuwe bestelling aanmaken op basis van de gegevens van deze?',
    },
    complementAddress: {
        en: 'Complement address',
        fr: "Complément d'adresse",
        nl: 'Adrescomplement',
    },
    complementAddressTooltip: {
        fr: "Le complémennt d'information permet de préciser l'adresse (Ex: Etage, N° de batiment ...).",
        en: 'The complement of information allows to specify the address (Ex: Floor, Building number ...).',
        nl: 'Het aanvullende informatie maakt het mogelijk het adres te specificeren (bijv. verdieping, gebouwnummer ...).',
    },
    warning: {
        en: 'Warning',
        fr: 'Attention',
        nl: 'Waarschuwing',
    },
    search: {
        en: 'Search',
        fr: 'Recherche',
        nl: 'Zoeken',
    },
    reset: {
        en: 'Reset filters',
        fr: 'Réinitialiser les filtres',
        nl: 'Filters resetten',
    },
    searchAddresses: {
        en: 'Filter addresses',
        fr: 'Filter les adresses',
        nl: 'Adressen filteren',
    },
    time: {
        en: 'Time',
        fr: 'Délai',
        nl: 'Tijdslimiet',
    },
    d: {
        en: 'D',
        fr: 'J',
        nl: 'D',
    },
    pickupDay: {
        en: 'Pickup day',
        fr: 'Jour de chargement',
        nl: 'Laaddag',
    },
    deliveryDay: {
        en: 'Delivery day',
        fr: 'Jour de livraison',
        nl: 'Bezorgdag',
    },
    deliveryZone: {
        en: 'Delivery zone',
        fr: 'Zone de livraison',
        nl: 'Bezorgzone',
    },
    pickupZone: {
        en: 'Pickup zone',
        fr: 'Zone de chargement',
        nl: 'Ophaalgebied',
    },
    mondayShort: {
        en: 'Mon',
        fr: 'Lun',
        nl: 'Ma',
    },
    tuesdayShort: {
        en: 'Tue',
        fr: 'Mar',
        nl: 'Di',
    },
    wednesdayShort: {
        en: 'Wed',
        fr: 'Mer',
        nl: 'Wo',
    },
    thursdayShort: {
        en: 'Thu',
        fr: 'Jeu',
        nl: 'Do',
    },
    fridayShort: {
        en: 'Fri',
        fr: 'Ven',
        nl: 'Vr',
    },
    saturdayShort: {
        en: 'Sat',
        fr: 'Sam',
        nl: 'Za',
    },
    sundayShort: {
        en: 'Sun',
        fr: 'Dim',
        nl: 'Zo',
    },
    monday: {
        en: 'Monday',
        fr: 'Lundi',
        nl: 'Maandag',
    },
    tuesday: {
        en: 'Tuesday',
        fr: 'Mardi',
        nl: 'Dinsdag',
    },
    wednesday: {
        en: 'Wednesday',
        fr: 'Mercredi',
        nl: 'Woensdag',
    },
    thursday: {
        en: 'Thursday',
        fr: 'Jeudi',
        nl: 'Donderdag',
    },
    friday: {
        en: 'Friday',
        fr: 'Vendredi',
        nl: 'Vrijdag',
    },
    saturday: {
        en: 'Saturday',
        fr: 'Samedi',
        nl: 'Zaterdag',
    },
    sunday: {
        en: 'Sunday',
        fr: 'Dimanche',
        nl: 'Zondag',
    },
    weCannotGuaranteeThisAddress: {
        en: 'Your address is not supported by our transportation plan. Please contact the JETFREEZE sales department to place your order: <b>jetfreeze@jetransporte.com</b>.',
        fr: "Votre adresse n'est pas pris en charge par notre plan de transport. Merci de contacter le service commercial JETFREEZE pour réaliser votre commande : <b>jetfreeze@jetransporte.com</b>.",
        nl: 'Uw adres wordt niet ondersteund door ons transportplan. Neem contact op met de verkoopafdeling van JETFREEZE om uw bestelling te plaatsen: <b>jetfreeze@jetransporte.com</b>.',
    },
    filesNotPossessed: {
        en: 'If you do not currently have the documents, please check this box.',
        fr: 'Si vous ne possedez pas actuellement les documents, merci de cocher cette case.',
        nl: 'Als u momenteel niet over de documenten beschikt, vink dan dit vakje aan.',
    },
    provideFilesLater: {
        en: 'You will have to send them by email later.',
        fr: 'Vous aurez à les communiquer par emails plus tard.',
        nl: 'U moet ze later per e-mail doorgeven.',
    },
    noFilesNowInfo: {
        en: "When you have the documents, don't forget to send them to the following email addresses : {{0}} / {{1}}",
        fr: "Lorsque vous serez en possession des documents, n'oubliez pas de les envoyer aux adresses emails suivantes : {{0}} / {{1}}",
        nl: 'Als u de documenten heeft, vergeet ze dan niet naar de volgende e-mailadressen te sturen : {{0}} / {{1}}',
    },
    suggestedAddresses: {
        en: 'Find an address',
        fr: 'Rechercher une adresse',
        nl: 'Zoek een adres',
    },
    isSurtaxed: {
        en: 'Is this address surtaxed ?',
        fr: 'Cette adresse est elle surtaxée ?',
        nl: 'Is dit adres een toeslag?',
    },
    instructions: {
        en: 'Instructions',
        fr: 'Instructions',
        nl: 'Instructies',
    },
    other: {
        en: 'Other',
        fr: 'Autre',
        nl: 'Ander',
    },
    refresh: {
        en: 'Refresh',
        fr: 'Rafraichir',
        nl: 'Vernieuwen',
    },
    logout: {
        en: 'Logout',
        fr: 'Se déconnecter',
        nl: 'Uitloggen',
    },
    articleReferences: {
        fr: 'Références marchandises',
        en: 'Article references',
        nl: 'Artikelreferenties',
    },
    defaultDeliveryType: {
        en: 'Default delivery type',
        fr: 'Type de livraison par défaut',
        nl: 'Standaard leveringstype',
    },
    transports: {
        en: 'Transports',
        fr: 'Transports',
        nl: 'Transporten',
    },
    allMyTransports: {
        en: 'All my transports',
        fr: 'Tous mes transports',
        nl: 'Al mijn transporten',
    },
    waitingForValidation: {
        en: 'Waiting for validation',
        fr: 'Attente de validation',
        nl: 'Wachten op validatie',
    },
    refused: {
        fr: 'Annulées',
        en: 'Cancelled',
        nl: 'Geannuleerd',
    },
    inProgress: {
        en: 'In progress',
        fr: 'En cours',
        nl: 'Bezig',
    },
    completed: {
        fr: 'Livrés',
        en: 'Delivered',
        nl: 'Geleverd',
    },
    orderEntry: {
        en: 'Transports',
        fr: 'Transports',
        nl: 'Transportopdrachten',
    },
    orderEntryFull: {
        en: 'Enter transport order(s)',
        fr: 'Saisie commande(s) transport',
        nl: 'Voer transportopdracht(en) in',
    },
    contacts: {
        en: 'Contacts',
        fr: 'Contacts',
        nl: 'Contacten',
    },
    tools: {
        en: 'Tools',
        fr: 'Outils',
        nl: 'Gereedschap',
    },
    userEntry: {
        en: 'User entry',
        fr: 'Saisie utilisateur',
        nl: 'Gebruikers invoer',
    },
    allTheUsers: {
        en: 'All the users',
        fr: 'Toutes les utilisateurs',
        nl: 'Alle gebruikers',
    },
    edit: {
        en: 'Edit',
        fr: 'Modifier',
        nl: 'Bewerken',
    },
    editDelivery: {
        en: 'Edit delivery',
        fr: 'Modifier la livraison',
        nl: 'Bewerking bewerken',
    },
    editPickup: {
        en: 'Edit pickup',
        fr: "Modifier l'enlèvement",
        nl: 'Bewerking ophalen',
    },
    deleteUser: {
        en: 'Delete user',
        fr: "Supprimer l'utilisateur",
        nl: 'Verwijder gebruiker',
    },
    sureDeleteUser: {
        en: 'Are you sure you want to delete the user? This action is final.',
        fr: "Êtes-vous certain de vouloir supprimer l'utilisateur ? Cette action est définitive.",
        nl: 'Weet u zeker dat u de gebruiker wilt verwijderen? Deze actie is definitief.',
    },
    clientEntry: {
        en: 'Client entry',
        fr: 'Saisie client',
        nl: 'Klantinvoer',
    },
    allTheClients: {
        en: 'All the clients',
        fr: 'Tous les clients',
        nl: 'Alle klanten',
    },
    priceList: {
        en: 'Price list',
        fr: 'Grille tarifaire',
        nl: 'Prijslijst',
    },
    code: {
        en: 'Code',
        fr: 'Code',
        nl: 'Code',
    },
    deleteClient: {
        en: 'Delete client',
        fr: 'Supprimer le client',
        nl: 'Verwijder klanten',
    },
    sureDeleteClient: {
        en: 'Are you sure you want to delete the client? This action is final.',
        fr: 'Êtes-vous certain de vouloir supprimer le client ? Cette action est définitive.',
        nl: 'Weet u zeker dat u de klanten wilt verwijderen? Deze actie is definitief.',
    },
    deleteLogisticConstraint: {
        en: 'Delete {{name}}',
        fr: 'Supprimer {{name}}',
        nl: 'Verwijder {{name}}',
    },
    sureDeleteLogisticConstraint: {
        en: 'Are you sure you want to delete the logistic constraint? This action is final.',
        fr: 'Êtes-vous certain de vouloir supprimer la contrainte logistique ? Cette action est définitive.',
        nl: 'Weet u zeker dat u de logistieke beperking wilt verwijderen? Deze actie is definitief.',
    },
    sureDeleteDeliveryType: {
        en: 'Are you sure you want to delete the delivery type? This action is final.',
        fr: 'Êtes-vous certain de vouloir supprimer le type de livraison ? Cette action est définitive.',
        nl: 'Weet u zeker dat u het leveringstype wilt verwijderen? Deze actie is definitief.',
    },
    companyEntry: {
        en: 'Company entry',
        fr: 'Saisie société',
        nl: 'Klantinvoer',
    },
    allTheCompanies: {
        en: 'All the companies',
        fr: 'Tous les sociétés',
        nl: 'Alle bedrijven',
    },
    exportFileFormat: {
        en: 'Export file format',
        fr: "Format de fichier d'exportation",
        nl: 'Bestandsindeling exporteren',
    },
    areAddressTimeSlotsMandatory: {
        en: 'Are address time slots mandatory ?',
        fr: 'Les créneaux horaires des adresses sont-ils obligatoires ?',
        nl: 'Zijn de tijdslots van adressen verplicht?',
    },
    isOperationCenterRequired: {
        en: 'Does the company use operation centers ?',
        fr: "La société utilise-t-elle des centres d'opérations ?",
        nl: 'Gebruikt het bedrijf operationele centra?',
    },
    deleteCompany: {
        en: 'Delete company',
        fr: 'Supprimer la société',
        nl: 'Bedrijf verwijderen',
    },
    someFilesAreMissing: {
        en: 'Some files are missing',
        fr: 'Certains fichiers sont manquants',
        nl: 'Sommige bestanden ontbreken',
    },
    sureDeleteCompany: {
        en: 'Are you sure you want to delete the company? This action is final.',
        fr: 'Êtes-vous certain de vouloir supprimer la société ? Cette action est définitive.',
        nl: 'Weet u zeker dat u het bedrijf wilt verwijderen? Deze actie is definitief.',
    },
    sureCancelOrder: {
        en: 'Are you sure you want to cancel order creation? All information provided will be lost.',
        fr: 'Voulez-vous vraiment annuler la création de la commande ? Toutes les informations fournies seront perdues.',
        nl: 'Weet u zeker dat u het aanmaken van een bestelling wilt annuleren? Alle verstrekte informatie gaat verloren.',
    },
    sureRemoveDelivery: {
        en: 'Are you sure you want to delete delivery? All information provided will be lost.',
        fr: 'Voulez-vous vraiment supprimer la livraison ? Toutes les informations fournies seront perdues.',
        nl: 'Weet u zeker dat u de levering wilt verwijderen? Alle verstrekte informatie gaat verloren.',
    },
    sureRemoveDeliveryTime: {
        en: 'Are you sure you want to delete delivery time? All information provided will be lost.',
        fr: 'Voulez-vous vraiment supprimer le délai de livraison ? Toutes les informations fournies seront perdues.',
        nl: 'Weet u zeker dat u de levertijd wilt verwijderen? Alle verstrekte informatie gaat verloren.',
    },
    sureRemoveAddress: {
        en: 'Are you sure you want to delete address? All information provided will be lost.',
        fr: "Voulez-vous vraiment supprimer l'adresse ? Toutes les informations fournies seront perdues.",
        nl: 'Weet u zeker dat u het adres wilt verwijderen? Alle verstrekte informatie gaat verloren.',
    },
    sureRemoveDepartment: {
        en: 'Are you sure you want to delete the department? All information provided will be lost.',
        fr: 'Voulez-vous vraiment supprimer le département ? Toutes les informations fournies seront perdues.',
        nl: 'Weet u zeker dat u de afdeling wilt verwijderen? Alle verstrekte informatie gaat verloren.',
    },
    publishedOn: {
        en: 'Published on',
        fr: 'Publié le',
        nl: 'Gepubliceerd op',
    },
    see: {
        en: 'Voir',
        fr: 'See',
        nl: 'Zie je wel',
    },
    seeMore: {
        en: 'See more',
        fr: 'Voir plus',
        nl: 'Bekijk meer',
    },
    seeLess: {
        en: 'See less',
        fr: 'Voir moins',
        nl: 'Zie minder',
    },
    remittance: {
        en: 'Seizure',
        fr: 'Saisie',
        nl: 'Inbeslagname',
    },
    createdAt: {
        en: 'Created at',
        fr: 'Créé le',
        nl: 'Gemaakt de',
    },
    helperBubbleInstruction: {
        fr: "Les instructions seront enregistrées pour les prochaines commandes (Ex : Les heures d'ouvertures de l’entrepôt sont 08h - 12h & 13h - 15h.)",
        en: 'The instructions will be recorded for the next orders (Ex : The opening hours of the warehouse are 08h - 12h & 13h - 15h.)',
        nl: 'De instructies worden opgeslagen voor de volgende bestellingen (Ex : De openingstijden van de magazijn zijn 08h - 12h & 13h - 15h.)',
    },
    helperBubbleDeliveryRequirement: {
        fr: "Vous souhaitez informer l'exploitation d'une instruction très précise pour cette livraison (Ex : Appeler le client 30 min avant arrivée)",
        en: 'You want to inform the exploitation of a very precise instruction for this delivery (Ex : Call the client 30 min before arrival)',
        nl: 'U wilt de exploitatie van een zeer precieze instructie voor deze levering informeren (Ex : Bel de klant 30 min voor aankomst)',
    },
    helperBubblePickupRequirement: {
        fr: "Vous souhaitez informer l'exploitation d'une instruction très précise pour cet enlèvement (Ex : Appeler l'entrepôt 30 min avant arrivée)",
        en: 'You want to inform the exploitation of a very precise instruction for this pickup (Ex : Call the warehouse 30 min before arrival)',
        nl: 'U wilt de exploitatie van een zeer precieze instructie voor deze ophaal (Ex : Bel de magazijn 30 min voor aankomst)',
    },
    loading: {
        en: 'Loading',
        fr: 'Chargement',
        nl: 'Bezig met laden',
    },
    status: {
        en: 'Status',
        fr: 'Statut',
        nl: 'Toestand',
    },
    creationDate: {
        en: 'Creation date',
        fr: 'Date de création',
        nl: 'Aanmaakdatum',
    },
    orderWithId: {
        en: 'Order #{{orderId}}',
        fr: 'Commande n°{{orderId}}',
        nl: 'Bestelling #{{orderId}}',
    },
    entryOrderNumber: {
        en: 'Entry/Order number',
        fr: 'Numéro de saisie',
        nl: 'Invoer/Bestelnummer',
    },
    searchReferences: {
        fr: 'Références Transport / DO',
        en: 'Transport / DO references',
        nl: 'Transport / DO referenties',
    },
    homeArticleEntry: {
        en: 'Actuality entry',
        fr: 'Saisie actualité',
        nl: 'Nieuwsbericht',
    },
    homeActualities: {
        en: 'Actualities',
        fr: 'Actualités',
        nl: 'Actualiteiten',
    },
    homeActualitys: {
        en: 'Articles',
        fr: 'Actualités',
        nl: 'Artikelen',
    },
    homeArticle: {
        en: 'Actuality',
        fr: 'Actualité',
        nl: 'Nieuw',
    },
    deleteHomeArticle: {
        en: 'Delete actuality',
        fr: "Supprimer l'actualité",
        nl: 'Verwijder nieuw',
    },
    sureDeleteHomeArticle: {
        en: 'Are you sure you want to delete the actuality? This action is final.',
        fr: "Êtes-vous certain de vouloir supprimer l'actualité ? Cette action est définitive.",
        nl: 'Weet u zeker dat u de klanten wilt nieuw? Deze actie is definitief.',
    },
    startDate: {
        en: 'Start date',
        fr: 'Date début',
        nl: 'Startdatum',
    },
    endDate: {
        en: 'End date',
        fr: 'Date fin',
        nl: 'Einddatum',
    },
    category: {
        en: 'Category',
        fr: 'Catégorie',
        nl: 'Categorie',
    },
    homeActuality: {
        en: 'Article',
        fr: 'Actualité',
        nl: 'Artikel',
    },
    homeArticles: {
        fr: 'Nouveautés',
        en: 'Novelties',
        nl: 'Nieuws',
    },
    deleteHomeActuality: {
        en: 'Delete article',
        fr: "Supprimer l'actualié",
        nl: 'Verwijder artikel',
    },
    sureDeleteHomeActuality: {
        en: 'Are you sure you want to delete the article? This action is final.',
        fr: "Êtes-vous certain de vouloir supprimer l'article ? Cette action est définitive.",
        nl: 'Weet u zeker dat u de klanten wilt artikel? Deze actie is definitief.',
    },
    url: {
        en: 'URL',
        fr: 'URL',
        nl: 'URL',
    },
    lastTransportInGoing: {
        en: 'Last transport in going',
        fr: 'Derniers transports en cours',
        nl: 'Laatste transport in gang',
    },
    lastViewedOrders: {
        en: 'Last viewed orders',
        fr: 'Dernières commandes consultées',
        nl: 'Laatst bekeken bestellingen',
    },
    news: {
        en: 'News',
        fr: 'Actualités',
        nl: 'Nieuws',
    },
    return: {
        en: 'Return',
        fr: 'Retour',
        nl: 'Opbrengst',
    },
    newOrder: {
        en: 'New order',
        fr: 'Nouvelle commande',
        nl: 'Nieuwe opdracht',
    },
    transport: {
        en: 'Transport',
        fr: 'Transport',
        nl: 'Verwijdering',
    },
    deliverys: {
        en: 'Delivery(s)',
        fr: 'Livraison(s)',
        nl: 'Levering(en)',
    },
    confirmation: {
        en: 'Confirmation',
        fr: 'Confirmation',
        nl: 'Bevestiging(en)',
    },
    confirmationBannerTextJetfreeze: {
        en: 'Your order must be placed before {{timeOrder}} (except Weekends and holidays) if you want it to be removed the next day.',
        fr: 'Votre commande doit être passée avant {{timeOrder}} (hors Weekends et jours fériés) si vous voulez qu’elle soit enlevée le jour suivant.',
        nl: 'Uw bestelling moet worden geplaatst voor {{timeOrder}} (behalve in het weekend en op feestdagen) als u wilt dat het de volgende dag wordt verwijderd.',
    },
    confirmationBannerTextPineau: {
        en: 'Your order must be placed before {{timeOrder}}, the {{dayOfTheWeek}} of the week S-1 of the delivery.',
        fr: 'Votre commande doit être passée avant {{timeOrder}}, le {{dayOfTheWeek}} de la semaine S-1 de la livraison.',
        nl: 'Uw bestelling moet worden geplaatst voor {{timeOrder}}, de {{dayOfTheWeek}} van de week S-1 van de levering.',
    },
    specifications: {
        en: 'Specifications',
        fr: 'Spécifications',
        nl: 'Specificaties',
    },
    orderDay: {
        en: 'Order day',
        fr: 'Jour de commande',
        nl: 'Besteldag',
    },
    next: {
        en: 'Next',
        fr: 'Suivant',
        nl: 'Volgende',
    },
    previous: {
        en: 'Previous',
        fr: 'Précédent',
        nl: 'Vorige',
    },
    cancelEntry: {
        en: 'Cancel entry',
        fr: 'Annuler la saisie',
        nl: 'Wis ingave',
    },
    pickup: {
        en: 'Pickup',
        fr: 'Enlèvement',
        nl: 'Verwijdering',
    },
    complement: {
        en: 'Complement',
        fr: 'Complément',
        nl: 'Aanvulling',
    },
    summary: {
        en: 'Summary',
        fr: 'Récapitulatif',
        nl: 'Overzicht',
    },
    saveTemporarily: {
        en: 'Save temporarily',
        fr: 'Enregistrer temporairement',
        nl: 'Tijdelijk opslaan',
    },
    research: {
        en: 'Research',
        fr: 'Rechercher',
        nl: 'Zoeken',
    },
    resetFilters: {
        en: 'Click to reset filters',
        fr: 'Cliquez pour réinitialiser les filtres',
        nl: 'Klik om filters te resetten',
    },
    addAddress: {
        en: 'Add address',
        fr: 'Ajouter une adresse',
        nl: 'Voeg een adres toe',
    },
    addArticle: {
        en: 'Add article',
        fr: 'Ajouter un article',
        nl: 'Voeg item toe',
    },
    addParcel: {
        en: 'Add parcel',
        fr: 'Ajouter colis',
        nl: 'Voeg pakket toe',
    },
    parcel: {
        en: 'Parcel',
        fr: 'Colis',
        nl: 'Pakket',
    },
    pickupInstruction: {
        en: 'Pickup instruction',
        fr: "Instruction d'enlèvement",
        nl: 'Verwijderingsinstructie',
    },
    pickupRequirement: {
        en: 'Pickup requirement',
        fr: "Impératif d'enlèvement",
        nl: 'Verwijderingsvereiste',
    },
    writeAnImperative: {
        en: 'Write an imperative',
        fr: 'Ecrire un impératif',
        nl: 'schrijf een gebiedende wijs',
    },
    pickupStartWindow: {
        en: 'Desired pickup start time',
        fr: "Heure de début d'enl. souhaitée",
        nl: 'Gewenste starttijd ophalen',
    },
    pickupEndWindow: {
        en: 'Desired pickup end time',
        fr: "Heure de fin d'enl. souhaitée",
        nl: 'Gewenste eindtijd collectie',
    },
    logisticConstraints: {
        en: 'Logistic constraints',
        fr: 'Contraintes logistiques',
        nl: 'Logistieke beperkingen',
    },
    select: {
        en: 'Select',
        fr: 'Sélectionner',
        nl: 'Selecteer',
    },
    selectAll: {
        en: 'Select all',
        fr: 'Tout sélectionner',
        nl: 'Selecteer alles',
    },
    deliveryInstructions: {
        en: 'Delivery instructions',
        fr: 'Instruction de livraison',
        nl: 'Levering instructie',
    },
    deliveryRequirement: {
        en: 'Delivery requirement',
        fr: 'Impératif de livraison',
        nl: 'Leveringsvereiste',
    },
    contact: {
        en: 'Contact',
        fr: 'Contact',
        nl: 'Contact',
    },
    contactType: {
        en: 'Contact role',
        fr: 'Rôle du contact',
        nl: 'Contactrol',
    },
    orderingPartyReference: {
        en: 'Ordering party reference',
        fr: "Référence donneur d'ordre",
        nl: 'Klantreferentie',
    },
    orderingPartyReferenceTooltip: {
        fr: 'Saisir votre référence pour cette commande de transport. Cette référence doit être unique et différente des autres commandes. (100 caractères max)',
        en: 'Enter your reference for this transport order. This reference must be unique and different from other orders. (100 characters max)',
        nl: 'Voer uw referentie in voor deze transportopdracht. Deze referentie moet uniek zijn en verschillend van andere bestellingen. (100 tekens max)',
    },
    referenceNumber: {
        en: 'Reference number',
        fr: 'Numéro de référence',
        nl: 'Referentienummer',
    },
    merchandiseComment: {
        en: 'Handling comment',
        fr: 'Commentaire manutention',
        nl: 'Handling comment',
    },
    handlingCommentTooltip: {
        en: 'This comment allows you to inform our logistics teams on the handling of goods.',
        fr: "Ce commentaire permet d'informer nos équipes logistiques sur la manutention des marchandises.",
        nl: 'Deze opmerking maakt het mogelijk onze logistieke teams te informeren over de behandeling van goederen.',
    },
    writeAComment: {
        en: 'Write a comment',
        fr: 'Ecrire un commentaire',
        nl: 'Schrijf een opmerking',
    },
    invoiceComment: {
        fr: 'Commentaire CMR',
        en: 'CMR comment',
        nl: 'CMR opmerking',
    },
    invoiceCommentTooltip: {
        fr: 'Ce commentaire sera affiché sur la CMR.',
        en: 'This comment will be displayed on the CMR.',
        nl: 'Deze opmerking wordt weergegeven op de CMR.',
    },
    typeOfDelivery: {
        en: 'Type of delivery',
        fr: 'Type de livraison',
        nl: 'Type levering',
    },
    telephoneAppointment: {
        en: 'Making appointments',
        fr: 'Prise de RDV',
        nl: 'Afspraken maken',
    },
    otNumber: {
        en: 'OT number',
        fr: 'Numéro OT',
        nl: 'OT nummer',
    },
    commentAppointment: {
        en: 'Comment appointment',
        fr: 'Commentaire rendez-vous',
        nl: 'Reageer afspraak',
    },
    appointmentReference: {
        en: 'Appointment reference',
        fr: 'Référence rendez-vous',
        nl: 'Afspraak referentie',
    },
    amount: {
        en: 'Amount',
        fr: 'Montant',
        nl: 'Bedrag',
    },
    againstReimbursement: {
        en: 'Against reimbursement',
        fr: 'Contre-remboursement',
        nl: 'Tegen vergoeding',
    },
    desiredWeek: {
        en: 'Desired week',
        fr: 'Semaine souhaitée',
        nl: 'Gewenste week',
    },
    chooseWeek: {
        en: 'Choose week',
        fr: 'Choisir semaine',
        nl: 'Kies week',
    },
    chooseDate: {
        en: 'Choose a date',
        fr: 'Choisir une date',
        nl: 'Kies een datum',
    },
    wishedDateOfDelivery: {
        en: 'Desired delivery date',
        fr: 'Date de livraison impérative souhaitée',
        nl: 'Gewenste leverdatum',
    },
    addHandlingUnit: {
        en: 'Add Handling Unit',
        fr: 'Ajouter Unité de manutention',
        nl: 'Logistieke eenheid toevoegen',
    },
    handlingUnit: {
        en: 'Handling Unit',
        fr: 'Unité de manutention',
        nl: 'Eenheid toevoegen',
    },
    HU: {
        en: 'HU(s)',
        fr: 'UM(s)',
        nl: 'HU(s)',
    },
    support: {
        en: 'Support',
        fr: 'Support',
        nl: 'Steun',
    },
    supports: {
        en: 'Supports',
        fr: 'Supports',
        nl: 'Ondersteunt',
    },
    emptySubHandlingError: {
        en: 'At least 1 article is required',
        fr: 'Au moins 1 article est requis',
        nl: 'Er is minimaal 1 artikel vereist',
    },
    ref: {
        en: 'Ref',
        fr: 'Ref',
        nl: 'Ref',
    },
    weight: {
        en: 'Weight',
        fr: 'Poids',
        nl: 'Gewicht',
    },
    totalWeight: {
        en: 'Total weight',
        fr: 'Poids total',
        nl: 'Totaal gewicht',
    },
    calculatedFootage: {
        en: 'Unit linear footage calculated with your dimensions',
        fr: 'Métrage linéaire unitaire calculé avec vos dimensions',
        nl: 'Eenheid lineaire beelden berekend met uw afmetingen',
    },
    unitLinearFootage: {
        en: 'Unit linear footage',
        fr: 'Métrage linéaire unitaire',
        nl: 'Eenheid lineaire beelden',
    },
    footage: {
        en: 'Footage',
        fr: 'Métrage',
        nl: 'filmmateriaal',
    },
    linearMeterSuffix: {
        en: 'LM',
        fr: 'ML',
        nl: 'LM',
    },
    long: {
        en: 'Long.',
        fr: 'Longueur',
        nl: 'Long',
    },
    width: {
        en: 'Width',
        fr: 'Largeur',
        nl: 'Breedte',
    },
    height: {
        en: 'Height',
        fr: 'Hauteur',
        nl: 'Hoog.',
    },
    addAFile: {
        en: 'Add a file',
        fr: 'Ajouter un fichier',
        nl: 'Voeg een bestand toe',
    },
    careForDimensions: {
        fr: "Marchandises hors normes (>5 mètres), assurez-vous d'avoir renseigné les bonnes dimensions. Nos dimensions max sont : Long 7 mètres max. Larg 2,45 mètres max. Haut 2,4 mètres max.",
        en: 'Non-standard goods (>5 meters), make sure you have entered the correct dimensions. Our maximum dimensions are: Long 7 meters max. Width 2.45 meters max. High 2.4 meters max.',
        nl: 'Niet-standaard goederen (>5 meter), zorg ervoor dat u de juiste afmetingen heeft ingevuld. Onze maximale afmetingen zijn: Lang maximaal 7 meter. Breedte maximaal 2,45 meter. Hoog 2,4 meter maximaal.',
    },
    hasMissingFile: {
        en: 'Missing files',
        fr: 'Fichiers manquants',
        nl: 'Ontbrekende bestanden',
    },
    missingDate: {
        en: 'The date is missing, please fill it in.',
        fr: 'La date est manquante, merci de la renseigner.',
        nl: 'De datum ontbreekt, vul deze in.',
    },
    clickOrDragYourFileHere: {
        en: 'Click or drag your file here.',
        fr: 'Cliquez ou glissez votre fichier ici.',
        nl: 'Klik of sleep uw bestand hierheen.',
    },
    total: {
        en: 'Total',
        fr: 'Total',
        nl: 'Total',
    },
    destinations: {
        en: 'Destination(s)',
        fr: 'Destination(s)',
        nl: 'Bestemming(en)',
    },
    article: {
        en: 'Article',
        fr: 'Article',
        nl: 'Artikel',
    },
    articles: {
        en: 'Article(s)',
        fr: 'Article(s)',
        nl: 'Artikel(en)',
    },
    editSummary: {
        en: 'Edit Summary',
        fr: 'Editer Récapitulatif',
        nl: 'Samenvatting bewerken',
    },
    trackingLink: {
        en: 'Tracking link',
        fr: 'Lien de suivi',
        nl: 'Trackinglink',
    },
    labels: {
        en: 'Labels',
        fr: 'Etiquettes',
        nl: 'Etiketten',
    },
    send: {
        en: 'Send',
        fr: 'Envoyer',
        nl: 'Gezant',
    },
    sendTrackingLink: {
        en: 'Send tracking link by email',
        fr: 'Envoie du lien de suivi par email',
        nl: 'Trackinglink per e-mail verzenden',
    },
    index: {
        en: 'Index',
        fr: 'Index',
        nl: 'Inhoudsopgave',
    },
    merchandises: {
        en: 'Merchandise(s)',
        fr: 'Marchandise(s)',
        nl: 'Handelswaar(en)',
    },
    addNewDelivery: {
        en: 'Add new delivery',
        fr: 'Ajouter nouvelle livraison',
        nl: 'Nieuwe levering toevoegen',
    },
    week: {
        en: 'Week {{i}}',
        fr: 'Semaine {{i}}',
        nl: 'Week {{i}}',
    },
    reference: {
        en: 'Reference',
        fr: 'Référence',
        nl: 'Referentie',
    },
    addUrl: {
        en: 'Add URL',
        fr: 'Ajouter une URL',
        nl: 'Voeg URL toe',
    },
    warninFileSettings: {
        en: 'Be careful, by adding files here, you completely overwrite the parameters of the company files.',
        fr: 'Attention, en ajoutant des fichiers ici, vous écrasez entierement les parametres des fichiers de la sociétés.',
        nl: 'Wees voorzichtig, door hier bestanden toe te voegen, overschrijft u de parameters van de bedrijfsbestanden volledig.',
    },
    writeReference: {
        en: 'Write reference',
        fr: 'Ecrire référence',
        nl: 'Schrijf referentie',
    },
    transportTracking: {
        en: 'Transport tracking',
        fr: 'Suivi transport',
        nl: 'Transport volgen',
    },
    supported: {
        en: 'Supported',
        fr: 'Prise en charge',
        nl: 'Ondersteund',
    },
    forwarding: {
        en: 'Forwarding',
        fr: 'Acheminement',
        nl: 'Doorsturen',
    },
    preparationForDelivery: {
        en: 'Preparation for delivery',
        fr: 'Mise en livraison',
        nl: 'Levering',
    },
    delivered: {
        en: 'Delivered',
        fr: 'Livré',
        nl: 'Geleverd',
    },
    carrier: {
        en: 'Carrier',
        fr: 'Transporteur',
        nl: 'Vervoerder',
    },
    case: {
        en: 'Case',
        fr: 'Dossier',
        nl: 'Dossier',
    },
    agency: {
        en: 'Agency',
        fr: 'Agence',
        nl: 'Bureau',
    },
    remittanceEntry: {
        en: 'Remittance entry',
        fr: 'Saisie remise',
        nl: 'Kortingsinvoer',
    },
    gedmouv: {
        en: 'Gedmouv',
        fr: 'Gedmouv',
        nl: 'Gedmouv',
    },
    trackingFooter: {
        en: '{{name}}, a subsidiary of the MOUSSET Group © Copyright 2022 - All rights reserved - Legal notice',
        fr: '{{name}}, une filiale du Groupe MOUSSET © Copyright 2022 - Tous droit réservés - Mentions légales',
        nl: '{{name}}, een dochteronderneming van de MOUSSET Group © Copyright 2022 - Alle rechten voorbehouden - Wettelijke vermeldingen',
    },
    plannedFor: {
        en: 'Planned for',
        fr: 'Estimé le',
        nl: 'Gepland voor',
    },
    removedOn: {
        en: 'Removed on',
        fr: 'Enlevé le',
        nl: 'Verwijder het',
    },
    deliveredOn: {
        en: 'Delivered on',
        fr: 'Livré le',
        nl: 'Geleverd op',
    },
    plannedThe: {
        fr: 'Planifié le',
        en: 'Planned the',
        nl: 'Gepland op',
    },
    billing: {
        en: 'Billing',
        fr: 'Facturation',
        nl: 'Facturering',
    },
    anomalies: {
        en: 'Anomalies',
        fr: 'Anomalies',
        nl: 'Afwijkingen',
    },
    price: {
        en: 'Price',
        fr: 'Prix',
        nl: 'prijs',
    },
    billNumber: {
        en: 'Bill number',
        fr: 'Numéro de facture',
        nl: 'Rekeningnummer',
    },
    authorizedDdeliveryTypes: {
        en: 'Types of delivery allowed',
        fr: 'Types de livraison autorisés',
        nl: 'Soorten levering toegestaan',
    },
    supplier: {
        en: 'Supplier',
        fr: 'Fournisseur',
        nl: 'Leverancier',
    },
    comfourNumber: {
        en: 'COMFOUR supplier number',
        fr: 'Numéro de fournisseur COMFOUR',
        nl: 'COMFOUR leveranciersnummer',
    },
    allegroNumber: {
        en: 'Order number to Suppliers (ALLEGRO/EDI)',
        fr: 'Numéro de commande aux fournisseurs (ALLEGRO/EDI)',
        nl: 'Bestelnummer naar leveranciers (ALLEGRO/EDI)',
    },
    allegroNumberShort: {
        en: 'Allegro/EDI number',
        fr: 'Numéro Allegro/EDI',
        nl: 'Allegro/EDI-nummer',
    },
    location: {
        en: 'Location',
        fr: 'Localisation',
        nl: 'Plaats',
    },
    informationFillInAddressInformation: {
        en: 'Information (Fill in address information)',
        fr: "Informations (Renseigner les informations sur l'adresse)",
        nl: 'Informatie (Vul adresgegevens in)',
    },
    toEnsureThatOurDriverArrivesEasily: {
        en: 'To ensure that our driver arrives easily at the site thanks to an accurate GPS route, we advise you to check the GPS coordinates of the address.\r\nThis information also allows us to detect arrivals and departures from the site.',
        fr: "Pour vous assurer que notre conducteur arrive facilement sur le site grâce à un itinéraire GPS précis, nous vous conseillons de vérifier les coordonnées GPS de l'adresse.\r\nCette information permet aussi de détecter les arrivées et départs du site.",
        nl: 'Om ervoor te zorgen dat onze chauffeur dankzij een nauwkeurige GPS-route gemakkelijk op de site arriveert, raden we u aan de GPS-coördinaten van het adres te controleren.\r\nDeze informatie stelt ons ook in staat om aankomsten en vertrekken van de site te detecteren.',
    },
    ifYourAddressIsNotListed: {
        en: 'If your address is not listed, you can place the cursor manually to get the GPS coordinates and save them.',
        fr: "Si votre adresse n'est pas répertoriée, vous pouvez placer le curseur manuellement pour obtenir les coordonées GPS et les enregistrer.",
        nl: 'Als uw adres niet in de lijst staat, kunt u de cursor handmatig plaatsen om de GPS-coördinaten te krijgen en op te slaan.',
    },
    placeCursorManually: {
        en: 'Place cursor manually',
        fr: 'Placer le curseur manuellement',
        nl: 'Cursor handmatig plaatsen',
    },
    moveTheCursor: {
        en: 'Move the cursor on the map to define the address you want.',
        fr: "Déplacer le curseur sur la carte pour définir l'adresse que vous souhaitez.",
        nl: 'Verplaats de cursor op de kaart om het gewenste adres te definiëren.',
    },
    latitude: {
        en: 'Latitude',
        fr: 'Latitude',
        nl: 'Breedtegraad',
    },
    longitude: {
        en: 'Longitude',
        fr: 'Longitude',
        nl: 'Lengtegraad',
    },
    invoiceEntry: {
        en: 'Invoices',
        fr: 'Factures',
        nl: 'Factuurinvoer',
    },
    allTheInvoices: {
        en: 'All the invoices',
        fr: 'Toutes les factures',
        nl: 'Alle facturen',
    },
    invoices: {
        en: 'Invoices',
        fr: 'Factures',
        nl: 'Facturen',
    },
    invoiceNumber: {
        en: 'Invoice number',
        fr: 'Numéro facture',
        nl: 'Factuurnummer',
    },
    number: {
        en: 'number',
        fr: 'Numéro',
        nl: 'Nummer',
    },
    InvoiceLabel: {
        en: 'Invoice label',
        fr: 'Libellé facture',
        nl: 'Factuurbeschrijving',
    },
    wording: {
        en: 'Wording',
        fr: 'Libellé',
        nl: 'Formulering',
    },
    amountExcludingVat: {
        en: 'Amount excluding VAT',
        fr: 'Montant HT',
        nl: 'Bedrag exclusief belasting',
    },
    deadlineDate: {
        en: 'Deadline date',
        fr: "Date d'échéance",
        nl: 'Deadline',
    },
    amountIncludingVat: {
        en: 'Amount including VAT',
        fr: 'Montant TTC',
        nl: 'Bedrag inclusief belasting',
    },
    deleteInvoice: {
        en: 'Delete invoice',
        fr: 'Supprimer la facture',
        nl: 'Factuur verwijderen',
    },
    sureDeleteInvoice: {
        en: 'Are you sure you want to delete the invoice ? This action is definitive.',
        fr: 'Êtes-vous certains de vouloir supprimer la facture ? Cette action est définitive.',
        nl: 'Weet u zeker dat u de bestelling wilt verwijderen ? Deze actie is definitief.',
    },
    transportNumber: {
        en: 'Transport number',
        fr: 'Numéro du transport',
        nl: 'Transportnummer',
    },
    file: {
        en: 'File',
        fr: 'Fichier',
        nl: 'Bestand',
    },
    urlFile: {
        en: 'URL file',
        fr: 'Fichier URL',
        nl: 'URL-bestand',
    },
    documentEntry: {
        en: 'Documents',
        fr: 'Documents',
        nl: 'Documentinvoer',
    },
    allTheDocuments: {
        en: 'All the documents',
        fr: 'Toutes les documents',
        nl: 'Alle documenten',
    },
    unit: {
        en: 'Unit',
        fr: 'Unité',
        nl: 'Eenheid',
    },
    tariffCode: {
        en: 'Tariff code',
        fr: 'Code tarif',
        nl: 'Tariefcode',
    },
    tariffType: {
        en: 'Type of tariff',
        fr: 'Type de tarif',
        nl: 'Tarieftype',
    },
    excelFile: {
        en: 'Excel file',
        fr: 'Fichier Excel',
        nl: 'Excel bestand',
    },
    prices: {
        en: 'Prices',
        fr: 'Tarifs',
        nl: 'Prijzen',
    },
    unitTaxed: {
        en: 'Unite taxed',
        fr: 'Unité taxé',
        nl: 'Belaste eenheid',
    },
    version: {
        en: 'Version',
        fr: 'Version',
        nl: 'Versie',
    },
    newEntry: {
        en: 'New entry',
        fr: 'Nouvelle Commande',
        nl: 'Nieuwe invoer',
    },
    pickupWindowTooltip: {
        en: 'The slots are intended to give you a more precise idea of the time you want to be picked up. Given that they can be affected by external factors such as traffic, planning they are not guaranteed and can be changed.',
        fr: "Les créneaux ont pour but de vous donner une idée plus précise du moment où vous souhaitez être enlevé. Étant donné qu'ils peuvent être affectés par des facteurs externes tels que la circulation, plannification ils ne sont pas garantis et peuvent être modifiés.",
        nl: 'De tijdvakken zijn bedoeld om u een meer precieze idee te geven van de tijd waarop u opgehaald wilt worden. Gezien ze kunnen worden beïnvloed door externe factoren zoals verkeer, planning zijn ze niet gegarandeerd en kunnen worden gewijzigd.',
    },
    deliveryComplements: {
        en: 'Delivery complements',
        fr: 'Compléments livraison',
        nl: 'Bezorgsupplementen',
    },
    wishedDateOfDeliveryTooltip: {
        en: 'The delivery date is intended to give you a more precise idea of the time you want to be delivered. Given that it can be affected by external factors such as planning, traffic, it cannot be guaranteed and can be changed by the carrier.',
        fr: "La date de livraison a pour but de donner une idée plus précise du moment où vous souhaitez être livré. Étant donné qu'elle peut être affectés par des facteurs externes tels que la plannification, circulation, elle ne peut être garantis et peut être modifiée par le transporteur.",
        nl: 'De leverdatum is bedoeld om u een meer precieze idee te geven van de tijd waarop u geleverd wilt worden. Gezien ze kunnen worden beïnvloed door externe factoren zoals planning, verkeer, kan het niet worden gegarandeerd en kan worden gewijzigd door de vervoerder.',
    },
    content2: {
        en: 'Content',
        fr: 'Contenu',
        nl: 'Inhoud',
    },
    legalMentions: {
        en: 'Legal mentions',
        fr: 'Mentions légales',
        nl: 'Juridische kennisgeving',
    },
    clickingValidate: {
        en: 'By clicking on "Validate", you accept the conditions of use of the site described in the Legal notices.',
        fr: 'En cliquant sur "Valider", vous acceptez les conditions d\'utilisation du site décrites dans les Mentions légales.',
        nl: 'Door op "Valideren" te klikken, aanvaardt u de gebruiksvoorwaarden van de site beschreven in de Juridische kennisgevingen.',
    },
    share: {
        en: 'Share',
        fr: 'Partager',
        nl: 'Delen',
    },
    shareTrackingLinkSubtitle: {
        en: 'Enter the emails to send the follow-up sharing link to.',
        fr: 'Saisissez les mails auxquels envoyer le lien de partage du suivis.',
        nl: 'Voer de e-mails in waarnaar de link voor het delen van de follow-up moet worden verzonden.',
    },
    estimateDeliveryDateDescription: {
        en: 'Estimate the delivery date of your transport from a loading place and a destination place.',
        fr: "Estimez la date de livraison de votre transport à partir d'un lieu de chargement et d'un lieu de destination.",
        nl: 'Bereken de leverdatum van uw transport vanaf een laadplaats en een bestemmingsplaats.',
    },
    estimateDeliveryDate: {
        en: 'Estimate delivery date',
        fr: 'Estimer la date de livraison',
        nl: 'Bereken leverdatum',
    },
    userGuide: {
        en: 'User guide',
        fr: 'Guide utilisateur',
        nl: 'Gebruikershandleiding',
    },
    userGuideDescription: {
        en: 'Discover how to use the site and its features.',
        fr: 'Découvrez comment utiliser le site et les fonctionnalités.',
        nl: 'Ontdek hoe u de site en zijn functies kunt gebruiken.',
    },
    pickupCode: {
        en: 'Postal code or loading department',
        fr: 'Code postal ou département de chargement',
        nl: 'Postcode of laaddepartement',
    },
    deliveryCode: {
        en: 'Postal code or delivery department',
        fr: 'Code postal ou département de livraison',
        nl: 'Postcode of leveringsdepartement',
    },
    deliveryDateResult: {
        en: 'Delivery estimated on {{date}}',
        fr: 'Livraison estimée le {{date}}',
        nl: 'Levering geschat op {{date}}',
    },
    estimate: {
        en: 'Estimate',
        fr: 'Estimer',
        nl: 'Schatten',
    },
    estimateDeliveryDateTitle: {
        en: 'Estimate the delivery date according to our theoretical planning',
        fr: 'Estimez la date de livraison selon notre planning théorique',
        nl: 'Bereken de leverdatum volgens onze theoretische planning',
    },
    supplierCodeNo: {
        en: 'Supplier code no',
        fr: 'Numéro de commande fournisseur',
        nl: 'Leverancier codenr',
    },
    clientCodeNo: {
        en: 'Client code no',
        fr: 'Numéro de commande client',
        nl: 'Klant codenr',
    },
    xmlCode: {
        en: 'XML code',
        fr: 'Code XML',
        nl: 'XML code',
    },
    noDesiredDate: {
        en: 'No desired date',
        fr: 'Pas de date souhaité',
        nl: 'Geen gewenste datum',
    },
    active: {
        en: 'Active',
        fr: 'Actif',
        nl: 'Bedrijfsmiddel',
    },
    noActive: {
        en: 'No active',
        fr: 'Non actif',
        nl: 'Niet actief',
    },
    noInstruction: {
        en: 'No instruction',
        fr: "Pas d'instruction",
        nl: 'Geen instructie',
    },
    noRequirement: {
        en: 'No requirement',
        fr: "Pas d'impératif",
        nl: 'Geen imperatief',
    },
    noComment: {
        en: 'No comment',
        fr: 'Pas de commentaire',
        nl: 'Geen commentaar',
    },
    noLogisticConstraint: {
        en: 'No logistic constraint',
        fr: 'Pas de contrainte logistique',
        nl: 'Geen logistieke beperking',
    },
    noDocument: {
        en: 'No document',
        fr: 'Pas de document',
        nl: 'Geen document',
    },
    maintenance1: {
        en: 'Order creation is currently not available.',
        fr: "La création de commande n'est actuellement pas disponible.",
        nl: 'Het aanmaken van bestellingen is momenteel niet beschikbaar.',
    },
    maintenance2: {
        fr: 'Vous pouvez néanmoins consulter vos commandes en cours.',
        en: 'You can nevertheless consult your current orders.',
        nl: 'U kunt echter uw huidige bestellingen raadplegen.',
    },
    maintenance3: {
        fr: 'Vous pouvez également consulter les informations de votre compte.',
        en: 'You can also consult the information of your account.',
        nl: 'U kunt ook de informatie van uw account raadplegen.',
    },
    print: {
        en: 'Print',
        fr: 'Imprimer',
        nl: 'Afdrukken',
    },
    printLabels: {
        en: 'Print labels',
        fr: 'Imprimer les étiquettes',
        nl: 'Labels afdrukken',
    },
    printLabelsSubtitle: {
        fr: "Choisissez le nombre d'étiquettes que vous voulez imprimer par page.",
        en: 'Choose the number of labels you want to print per page.',
        nl: 'Kies het aantal labels dat u per pagina wilt afdrukken.',
    },
    visibleOperatingCenters: {
        en: 'Visible Pickup Platforms',
        fr: 'Plateformes Enlèvement visibles',
        nl: 'Zichtbare ophaalplatforms',
    },
    pickupType: {
        en: 'Pickup type',
        fr: "Type d'enlèvement",
        nl: 'Ophaaltype',
    },
    dockingPlatform: {
        en: 'Berthing platform',
        fr: 'Plateforme de remise à quai',
        nl: 'Ligplatform',
    },
    docksidePickupDate: {
        en: 'Dockside pickup date',
        fr: 'Date de remise à quai',
        nl: 'Datum van afhalen aan de kade',
    },
    deliveryDateType: {
        fr: 'Delai de livraison',
        en: 'Delivery date',
        nl: 'Leveringsdatum',
    },
    nbOfLabel: {
        en: 'Number of labels',
        fr: "Nombre d'étiquettes",
        nl: 'Aantal labels',
    },
    minDays: {
        fr: "Nb minimum de jours après la date d'enlèvement",
        en: 'Minimum number of days after the pickup date',
        nl: 'Minimum aantal dagen na de ophaaldatum',
    },
    waitingLabelsForValidation: {
        fr: 'Vous pourrez imprimer les étiquettes lorsque votre commande sera validée.',
        en: 'You will be able to print the labels when your order is validated.',
        nl: 'U kunt de labels afdrukken wanneer uw bestelling is gevalideerd.',
    },
    waitingLabelsForTransport: {
        fr: 'Vous pourrez imprimer les étiquettes lorsque tous les transports auront été générés.',
        en: 'You will be able to print the labels when all transports have been generated.',
        nl: 'U kunt de labels afdrukken wanneer alle transporten zijn gegenereerd.',
    },
    printSummary: {
        en: 'Print summary',
        fr: 'Imprimer le récapitulatif',
        nl: 'Print samenvatting',
    },
}
